import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  message,
  Select,
  Steps,
  Table,
  Typography,
  Upload,
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { UploadOutlined } from '@ant-design/icons'

import {
  getListStudents,
  updateListStudents,
} from '../../components/admin/api'
import { Dashboard } from '../layout/Dashboard'
import { statusKey, displayNumbers } from '~/utils/constants'
import CompletionLayout from '../layout/CompletionLayout'
import axios from 'axios'
import { excelToJson } from '~/utils/exel'
import { useAlphaStore } from '~/context'
import toast from 'react-hot-toast'
import { t } from 'i18next'

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

const StudentManagementPage = () => {
  const { school } = useAlphaStore()
  const [page, setPage] = useState<number>(1)
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const [listStudents, setListStudents] = useState<any[]>([])
  const [listImported, setListImported] = useState<any[]>([])
  const [listBeforeUpload, setListBeforeUpload] = useState<any[]>([])
  const [confirmed1, setConfirmed1] = useState<boolean>(false)
  const [confirmed2, setConfirmed2] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(50)
  const [pageSizeList, setPageSizeList] = useState<number>(50)
  const [currentList, setCurrentList] = useState<number>(1)
  const [current, setCurrent] = useState<number>(1)
  const [uploadFile, setUploadFile] = useState<any>(null)

  useEffect(() => {
    const fetchListStudents = async () => {
      const { data } = await getListStudents()
      if (!data) return
      setListStudents(data.data)
    }
    fetchListStudents()
  }, [listImported, currentStepIdx, page])

  const keyInfo = {
    ID: 'username',
    パスワード: 'password',
    ステータス: 'status',
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: 'ID',
      dataIndex: 'username',
      key: 'username',
      width: '50%',
      align: 'center',
      className: 'whitespace-nowrap',
    },
    {
      title: 'パスワード',
      dataIndex: 'password',
      key: 'password',
      width: '50%',
      align: 'center',
      className: 'whitespace-nowrap',
    },
  ]

  const dataImported = listImported
    ?.filter((inf) => inf.username && inf.password)
    .map((info, idx) => {
      return {
        key: idx,
        username: !Number(info.username)
          ? info.username.toLowerCase()
          : info.username,
        password: info.password,
      }
    })

  const data = listStudents
    ?.filter((inf) => inf.status === 0 && inf.username && inf.password)
    .map((info, idx2) => {
      return {
        key: idx2,
        username: info.username,
        password: info.password,
      }
    })

  const handleUpload = async () => {
    if (!school) {
      toast.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('when call alpha/file-upload, error: school is not found')
      return false
    }

    const schoolId = school._id
    const schoolName = school.attributes.schoolName
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `student-user-pass-list/${schoolName}_${schoolId}_${fileId}_${Date.now()}`
    const studentFormUpload = new FormData()
    studentFormUpload.append('file', uploadFile as any)
    studentFormUpload.append('filename', fileId)
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_REST_API_URL}/alpha/file-upload`,
        studentFormUpload,
      )
      const rawData = await excelToJson(data.data)
      const processData = rawData.map((obj: any) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            keyInfo[key] ?? key,
            value,
          ]),
        ),
      )
      let executeData: Array<any> = []
      processData.map((item) => {
        if (item['status'] === statusKey.DEACTIVE) {
          executeData.push(item)
        } else {
          item['status'] = statusKey.ACTIVE
          executeData.push(item)
        }
      })
      setListImported(executeData)
      setListBeforeUpload(executeData)
      setCurrentStepIdx(1)
    } catch (e) {
      message.error('エラーが発生しました')
    }
  }

  const prev = () => {
    setCurrent(1)
    setCurrentList(1)
    setConfirmed1(false)
    setConfirmed2(false)
    setPageSizeList(50)
    if (currentStepIdx === 1) {
      setCurrentStepIdx(0)
    } else setPage(1)
  }

  const next = async () => {
    if (page === 2 && !uploadFile && currentStepIdx !== 1) {
      message.error('Excelファイルを選択してください')
      return
    }
    if (page === 2 && uploadFile && currentStepIdx !== 1) {
      await handleUpload()
      setUploadFile(null)
    }
    if (page === 2 && currentStepIdx === 1) {
      await updateListStudents(listBeforeUpload)
      setPage(3)
    }
  }

  const changePageSize = (value) => {
    setCurrent(1)
    setPageSize(value)
  }

  const disabled = () => {
    return (currentStepIdx === 0 && !confirmed1) ||
      (currentStepIdx === 1 && !confirmed2)
      ? true
      : false
  }

  const backToTop = () => {
    setPage(1)
    setConfirmed1(false)
    setConfirmed2(false)
    setCurrentStepIdx(0)
    setUploadFile(null)
    setCurrent(1)
    setCurrentList(1)
    setPageSizeList(50)
  }

  const steps = [
    {
      title: '①先生情報をアップロード',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file: any) => {
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setListImported([])
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">生徒情報をアップロード</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-28">(XLSXファイル式)</Text>
          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            生徒情報を正しく記入しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: ' ②確認',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="mb-5 w-full flex justify-start ml-196">
            <Select
              size="small"
              className="w-30 rounded-5px"
              placeholder="表示数"
              value={pageSize}
              dropdownAlign={{
                offset: [0, -2], // align offset
              }}
              onChange={changePageSize}
            >
              {displayNumbers?.map((d) => (
                <Option className="option-small" value={d.value} key={d.text}>
                  {d.text}
                </Option>
              ))}
            </Select>
          </div>
          <Table
            size="small"
            style={{ minWidth: 300 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            columns={tableColumns}
            dataSource={dataImported}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              defaultPageSize: 50,
              pageSize: pageSize,
              position: ['bottomCenter'],
              current: current,
              onChange(current) {
                setCurrent(current)
              },
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
            disabled={dataImported?.length === 0}
          >
            記入したデータは正しいでしょうか？
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard
      selectedMenu={2}
      navbar="管理ページ"
    >
      <div className="flex justify-center mt-14">
        <div>
          {page === 1 && (
            <>
              <span>登録人数 {data.length}</span>
              <Table
                columns={tableColumns}
                dataSource={data}
                loading={false}
                size="small"
                style={{ minWidth: 300 }}
                bordered={true}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: pageSizeList,
                  pageSize: pageSizeList,
                  position: ['bottomCenter'],
                  current: currentList,
                  onChange(current, size) {
                    setCurrentList(pageSizeList !== size ? 1 : current)
                    setPageSizeList(size)
                  },
                }}
              />
              <div className="flex justify-center mt-7">
                <Button
                  type="primary"
                  className="w-64"
                  onClick={() => setPage(2)}
                >
                  生徒情報をアップロード
                </Button>
              </div>
            </>
          )}

          {page === 2 && (
            <>
              <Steps
                labelPlacement="vertical"
                size="default"
                current={currentStepIdx}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">
                {steps[currentStepIdx].content}
              </div>
              <div className="steps-action text-center">
                <Button type="primary" className="h-8 w-24 mx-2" onClick={prev}>
                  戻る
                </Button>
                <Button
                  type="primary"
                  className="h-8 w-24"
                  // loading={uploading}
                  onClick={next}
                  disabled={disabled()}
                >
                  {currentStepIdx === 0 ? '次へ' : '登録'}
                </Button>
              </div>
            </>
          )}

          {page === 3 && (
            <div>
              <CompletionLayout
                message="登録完了"
                button={
                  <Button type="primary" onClick={backToTop}>
                    管理ページ（先生）TOPに戻る
                  </Button>
                }
              />
            </div>
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default StudentManagementPage
