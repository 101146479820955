import { UserGender } from '@alpha/core'
import { Button, Select } from 'antd'
import axios from 'axios'
import { LegacyRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import { useAlphaStore } from '~/context'
import { useQuery, useSchoolGradesApiQuery } from '~/hooks/hooks'
import { schoolHasEnduranceTest } from '~/utils'
import { API_URL } from '~/utils/api'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { makeYearName } from '~/utils/number'
import { getCurrentPrefectureQuestions } from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import type { StudentQuestionExtra } from '~/utils/types'
import { Dashboard } from '../layout/Dashboard'
import ClassesReportTable from './report-test/components/ClassesReportTable'
import ClubsReportTable from './report-test/components/ClubsReportTable'
import OverallRankingTable from './report-test/components/OverallRankingTable'
import OverallTable from './report-test/components/OverallTable'
import QuestionnaireTable from './report-test/components/QuestionnaireTable'
import ReportMenu, {
  TReportMenuIndex,
} from './report-test/components/ReportMenu'
import ReportScoreGraphs from './report-test/components/ReportScoreGraphs'
import ReportTestTable from './report-test/components/ReportTestTable'
import { showTextDetail } from './report-test/utils'

const { Option } = Select

// const showTypes = ['集計一覧', '学年結果', 'クラス結果', '運動部結果']

/**
 * Path: /test_report
 */
const ReportTestPage = () => {
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const { school } = useAlphaStore()
  const hasEnduranceTest = schoolHasEnduranceTest(school?.attributes)

  const [statistics, setStatistics] = useState<Record<string, any>>({})
  const [isLoading, setLoading] = useState(true)

  const [listQuestion, setListQuestion] = useState<number[]>([])
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const [questionExtra, setQuestionExtra] = useState<StudentQuestionExtra>({})
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [testYears, setTestYears] = useState<number[]>([])

  useEffect(() => {
    ;(async () => {
      if (school && listQuestion.length && testYears.length) {
        setLoading(true)
        try {
          const newStatistic = {}
          for (const year of testYears) {
            const res = await axios.get(
              `${API_URL}/alpha/v1/school/statistic`,
              {
                params: {
                  testYear: year,
                },
              },
            )

            newStatistic[year] = res.data
          }
          setStatistics(newStatistic)
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [school, listQuestion, testYears])

  const { loading: yearsLoading } = useQuery<any>({
    queryFn: async () => {
      const res = await axios.get(
        `${API_URL}/alpha/v1/school/statistic-test-years`,
      )

      if (!res.data.includes(currentYear)) res.data.push(currentYear)
      setTestYears(res.data.sort((a, b) => (a < b ? 1 : -1)))
    },
  })

  const { data: grades, loading: gradesLoading } = useSchoolGradesApiQuery()

  const loading = isLoading || yearsLoading || gradesLoading

  const reportRef = useRef<HTMLDivElement>()
  // TODO: Fix ResponsiveContainer width not recalculate when printing
  const handlePrint = useReactToPrint({
    content: () => reportRef.current ?? null,
    bodyClass: 'p-4',
  })

  const [reportIndex, setReportIndex] = useState<TReportMenuIndex>(null)

  useEffect(() => {
    if (!school) return

    for (const allQuestionsItem of allQuestions) {
      const extraQuestion = getExtraQuestion(
        school.attributes.schoolName,
        allQuestionsItem,
      )
      if (allQuestionsItem.id === 45 && extraQuestion) {
        setQuestionExtra(extraQuestion)
      }
    }

    const questionList = getCurrentPrefectureQuestions(
      school.attributes.prefectureCode,
      school._id,
      isElementarySchool,
      school.attributes.schoolCategoryCode,
    )

    setListQuestion(questionList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school])

  // const schoolReportTestSummary =
  //   statistics?.[currentYear - 1]?.schoolReportTestSummary
  // const schoolReportTestScoreChartSummary =
  //   statistics?.[currentYear - 1]?.schoolReportTestScoreChartSummary

  return (
    <Dashboard selectedMenu={5} navbar={t('集計結果')}>
      <>
        <ReportMenu
          t={t}
          reportIndex={reportIndex}
          setReportIndex={setReportIndex}
          handlePrint={handlePrint}
        />

        <div className={reportIndex ? 'block' : 'hidden'}>
          <div className="flex justify-center">
            <Button
              type="primary"
              className="w-40"
              style={{
                fontWeight: 700,
                fontSize: 16,
                lineHeight: '24px',
                height: 40,
                margin: '17px 0 24px',
              }}
              onClick={() => setReportIndex(null)}
            >
              {t('一覧に戻る')}
            </Button>
          </div>

          <div
            className="text-center mb-6"
            style={{
              width: '100%',
              background: 'rgba(3, 110, 184, 0.5)',
              borderBottom: '1px solid #C4C4C4',
              paddingTop: 8,
            }}
          >
            <div
              className={reportIndex === 6 ? '' : 'pb-2'}
              style={{
                fontWeight: 700,
                fontSize: 16,
                lineHeight: '24px',
                color: '#333333',
              }}
            >
              {/* TODO fix type */}
              <span>{t(showTextDetail[reportIndex as any])}</span>

              {reportIndex === 6 && (
                <>
                  <div
                    style={{
                      fontSize: 10,
                      lineHeight: '15px',
                      marginBottom: 3,
                    }}
                  >
                    ※ {t('全国平均のTスコアを50とする')}
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      lineHeight: '15px',
                      marginBottom: 3,
                    }}
                  >
                    ※{' '}
                    {t(
                      'こちらの平均値は2年前の文部科学省データを使用しています。',
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <div style={{ width: 1185 }} className="mt-3">
              {/* page 1 */}
              <div className={reportIndex === 1 ? 'block' : 'hidden'}>
                {(testYears.length > 1 ||
                  (testYears.length === 1 &&
                    !testYears.includes(currentYear))) && (
                  <div className="mb-4">
                    <Select
                      value={selectedYear}
                      onChange={(v) => setSelectedYear(v)}
                    >
                      {testYears.map((testYear) => (
                        <Option key={testYear} value={testYear}>
                          {testYear} ({makeYearName(testYear)})
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}

                <div className="space-y-8">
                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-male-test">
                      {t('平均値')}
                      {t('（男子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showType="average"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>

                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-female-test">
                      {t('平均値')}
                      {t('（女子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showType="average"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>

                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-male-test">
                      {t('標準偏差')}
                      {t('（男子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showType="deviation"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-female-test">
                      {t('標準偏差')}
                      {t('（女子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showType="deviation"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                </div>
              </div>

              <div className={reportIndex === 2 ? 'block' : 'hidden'}>
                {(testYears.length > 1 ||
                  (testYears.length === 1 &&
                    !testYears.includes(currentYear))) && (
                  <div className="mb-4">
                    <Select
                      value={selectedYear}
                      onChange={(v) => setSelectedYear(v)}
                    >
                      {testYears.map((testYear) => (
                        <Option key={testYear} value={testYear}>
                          {testYear} ({makeYearName(testYear)})
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="mt-8 space-y-8">
                  <div className="space-y-2 page-break-auto">
                    <OverallTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <OverallTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <OverallTable
                      schoolReportTestSummary={
                        statistics[selectedYear]
                          ?.schoolReportTestByClassSummaryAll
                      }
                      gender={undefined}
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <OverallRankingTable
                      schoolReportTestSummary={
                        statistics[selectedYear]
                          ?.rankingSchoolReportTestSummaryAll
                      }
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                </div>
              </div>
              <div className={reportIndex === 3 ? 'block' : 'hidden'}>
                <div className="mt-8 space-y-8">
                  <div className="page-break-auto">
                    <QuestionnaireTable
                      questionnaireSummary={
                        statistics[selectedYear]?.questionnaireSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      listQuestion={listQuestion}
                    />
                  </div>
                  <div className="page-break-auto">
                    <QuestionnaireTable
                      questionnaireSummary={
                        statistics[selectedYear]?.questionnaireSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      listQuestion={listQuestion}
                    />
                  </div>
                </div>
              </div>
              <div className={reportIndex === 4 ? 'block' : 'hidden'}>
                <div className="mt-8 space-y-8">
                  <div className="page-break-auto">
                    <ClassesReportTable
                      reportTestByClassSummary={
                        statistics[selectedYear]?.schoolReportTestByClassSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showType="average"
                      showPage="page"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>

                  <div className="page-break-auto">
                    <ClassesReportTable
                      reportTestByClassSummary={
                        statistics[selectedYear]?.schoolReportTestByClassSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showType="average"
                      showPage="page"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                </div>
              </div>
              <div className={reportIndex === 5 ? 'block' : 'hidden'}>
                <div className="mt-8 space-y-8">
                  <div className="page-break-auto">
                    <ClubsReportTable
                      questionExtra={questionExtra}
                      extraActivitySummary={
                        statistics[selectedYear]?.extraActivitySummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showPage="page"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="page-break-auto">
                    <ClubsReportTable
                      questionExtra={questionExtra}
                      extraActivitySummary={
                        statistics[selectedYear]?.extraActivitySummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showPage="page"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                </div>
              </div>
              <div className={reportIndex === 6 ? 'block' : 'hidden'}>
                {(testYears.length > 1 ||
                  (testYears.length === 1 &&
                    !testYears.includes(currentYear))) && (
                  <div className="mb-4">
                    <Select
                      value={selectedYear}
                      onChange={(v) => setSelectedYear(v)}
                    >
                      {testYears.map((testYear) => (
                        <Option key={testYear} value={testYear}>
                          {testYear} ({makeYearName(testYear)})
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="w-full">
                  <ReportScoreGraphs
                    chartSummary={
                      statistics[selectedYear]
                        ?.schoolReportTestScoreChartSummary
                    }
                    grades={grades}
                    loading={loading}
                    hasEnduranceTest={hasEnduranceTest}
                    isUsingJp={isUsingJp}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hidden">
            <div
              ref={reportRef as LegacyRef<HTMLDivElement>}
              style={{ width: 1185 }}
              className="mt-3 form-print"
            >
              <div>
                <div className="space-y-8">
                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-male-test">
                      {t('平均値')}
                      {t('（男子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showType="average"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-female-test">
                      {t('平均値')}
                      {t('（女子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showType="average"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-male-test">
                      {t('標準偏差')}
                      {t('（男子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showType="deviation"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <div className="h-6 flex justify-center items-center text-xs text-white font-black table-female-test">
                      {t('標準偏差')}
                      {t('（女子）')}
                    </div>
                    <ReportTestTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showType="deviation"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-8 space-y-8">
                  <div className="space-y-2 page-break-auto">
                    <OverallTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <OverallTable
                      schoolReportTestSummary={
                        statistics[selectedYear]?.schoolReportTestSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <OverallTable
                      schoolReportTestSummary={
                        statistics[selectedYear]
                          ?.schoolReportTestByClassSummaryAll
                      }
                      gender={undefined}
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                  <div className="space-y-2 page-break-auto">
                    <OverallRankingTable
                      schoolReportTestSummary={
                        statistics[selectedYear]
                          ?.rankingSchoolReportTestSummaryAll
                      }
                      loading={loading}
                      isUsingJp={isUsingJp}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-8 space-y-8">
                  <div className="page-break-auto">
                    <QuestionnaireTable
                      questionnaireSummary={
                        statistics[selectedYear]?.questionnaireSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      listQuestion={listQuestion}
                    />
                  </div>

                  <div className="page-break-auto">
                    <QuestionnaireTable
                      questionnaireSummary={
                        statistics[selectedYear]?.questionnaireSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      listQuestion={listQuestion}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-8 space-y-8">
                  <div className="page-break-auto">
                    <ClassesReportTable
                      reportTestByClassSummary={
                        statistics[selectedYear]?.schoolReportTestByClassSummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showType="average"
                      showPage="print"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="page-break-auto">
                    <ClassesReportTable
                      reportTestByClassSummary={
                        statistics[selectedYear]?.schoolReportTestByClassSummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showType="average"
                      showPage="print"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-8 space-y-8">
                  <div className="page-break-auto">
                    <ClubsReportTable
                      questionExtra={questionExtra}
                      extraActivitySummary={
                        statistics[selectedYear]?.extraActivitySummary
                      }
                      gender={UserGender.Male}
                      loading={loading}
                      showPage="print"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                  <div className="page-break-auto">
                    <ClubsReportTable
                      questionExtra={questionExtra}
                      extraActivitySummary={
                        statistics[selectedYear]?.extraActivitySummary
                      }
                      gender={UserGender.Female}
                      loading={loading}
                      showPage="print"
                      hasEnduranceTest={hasEnduranceTest}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full">
                  <ReportScoreGraphs
                    chartSummary={
                      statistics[selectedYear]
                        ?.schoolReportTestScoreChartSummary
                    }
                    grades={grades}
                    loading={loading}
                    hasEnduranceTest={hasEnduranceTest}
                    isUsingJp={isUsingJp}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  )
}

export default ReportTestPage
