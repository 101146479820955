export const pageSize = 50

/**
 * Update every year.
 */
export const currentYear = 2025

export const ENV = process.env.REACT_APP_ENV as 'prod' | 'stg' | 'local'

if (ENV !== 'prod' && ENV !== 'stg' && ENV !== 'local') {
  console.warn(
    'WARNING: The environment variable REACT_APP_ENV is invalid! REACT_APP_ENV:',
    ENV,
  )
}

export const LOCAL_STORAGE_LANGUAGE_KEY = 'lg'
export const LOCAL_STORAGE_POPUP = 'popup'

const params =
  typeof window !== 'undefined' ? new URL(document.URL).searchParams : undefined

export type TLang = 'en' | 'ja'

export let langParam: null | string | undefined = params?.get(
  LOCAL_STORAGE_LANGUAGE_KEY,
)

if (langParam !== 'en' && langParam !== 'ja') {
  langParam = null
}

export const now = new Date()
export const thisYear = now.getFullYear()
export const copyrightYear = thisYear
export const lastMonth = now.getMonth()
export const thisMonth = lastMonth + 1

export const globalInputKeys = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'enduranceRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
] as const

/**
 * App version for both the school and the students app.
 */
export const APP_VERSION = `${process.env.REACT_APP_COMMIT_SHA?.substring(
  0,
  7,
)}.${currentYear}.${ENV}`

export type TUserGender = 'MALE' | 'FEMALE'

export type SchoolCategoryCode = 'B1' | 'C1' | 'D1'

export enum VIDEO_TYPE {
  PUBLIC = 'PUBLIC',
  ADVISE = 'ADVISE',
  TEST_VIDEO = 'TEST_VIDEO',
}

export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL ?? ''

export const isProduction = !!API_URL?.includes(
  'https://api-v2.alpha-pestalozzi.com',
)

export const roundDecimal = (v: number, d: number) =>
  (Math.round(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)

export const getLastIndex = (arr: any[], fn: (t: any) => boolean) => {
  let result = -1
  for (let index = 0; index < arr.length; index++) {
    if (fn(arr[index])) result = index
  }
  return result
}


export const getGradeSchool = (role: string, schoolGrade: number) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
      return '全学年'
    case 'GRADE_ADMIN':
    case 'CLASS_ADMIN':
      return `${schoolGrade}年`
    default:
      break
  }
}

export const getClassSchool = (role: string, schoolClass: number) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
    case 'GRADE_ADMIN':
      return '全クラス'
    case 'CLASS_ADMIN':
      return `${schoolClass}組`
    default:
      break
  }
}

export const getRoleSchool = (role: string) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
      return '管理者'
    case 'GRADE_ADMIN':
      return '学年管理者'
    case 'CLASS_ADMIN':
      return 'クラス管理者'
    default:
      return role
  }
}

export const getGenderSchool = (gender: string) => {
  switch (gender) {
    case 'MALE':
      return '男'
    default:
      return '女'
  }
}

export const dataType = {
  STRING: 'string',
  NUMBER: 'number',
}

export const statusKey = {
  ACTIVE: 0,
  DEACTIVE: 1,
}

export const displayNumbers = [
  { text: '20　件/ページ', value: 20 },
  { text: '50　件/ページ', value: 50 },
  { text: '100　件/ページ', value: 100 },
]


export const getMediaUrl = (url: string) => {
  if (url.startsWith('https://alpha-2023.s3.ap-northeast-1.amazonaws.com')) {
    return url.replaceAll('https://alpha-2023.s3.ap-northeast-1.amazonaws.com', STORAGE_URL)
  }
  return url
}
