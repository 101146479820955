import React, { ErrorInfo } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { reportErrorSlack } from '~/utils/reportError'
import ErrorFallback from './components/cards/ErrorFallback'
import { VERSION_CHECKING_INTERVAL } from './school-utils/constants'
import { initializeInterceptors } from './school-utils/interceptors'
import { checkSchoolVersion } from './school-utils/ver'

// WARNING: This order is important! index.css -> antd.css
import './index.css'
// WARNING: This order is important!
import './antd.css'

import moment from 'moment'
import 'moment/locale/ja'

// import i18n (needs to be bundled ;))
import App from './App'
import './i18n'

// run checkSchoolVersion once per 6 hours
setInterval(checkSchoolVersion, VERSION_CHECKING_INTERVAL)

initializeInterceptors()

moment.locale('ja')

ReactDOM.render(
  <React.StrictMode>
    {/* <link rel="stylesheet" href="https://rsms.me/inter/inter.css" /> */}
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error, info: ErrorInfo) => {
        reportErrorSlack(error, info)
      }}
    >
      <App />
      <Toaster
        toastOptions={{
          // Default options for specific types
          error: {
            duration: 5000,
          },
        }}
      />
      {/* <MaintainNoticePage /> */}
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)
