import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Steps, Table, Typography, Upload } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { RcFile } from 'antd/lib/upload'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { read, set_cptable, utils } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import toast from 'react-hot-toast'
import { useAlphaStore } from '~/context'
import { updateSurvey } from '~/services/api/questions'
import type { ProcessingUploadQuestionnaireDataRequestStudent } from '~/types/api/alpha/questions'
import isJapanese from '~/utils/isJapanese'
import { getCurrentPrefectureQuestionsWithPrefName } from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import { AnswerType, StudentQuestionExtra } from '~/utils/types'
import { getValidPositiveNumberFromExcelFile } from '~/utils/validations'
import { Dashboard } from '../layout/Dashboard'
import QuestionnaireCompletionLayout from '../layout/QuestionnaireCompletionLayout'
import QuestionnairePanel from './QuestionnariePanel'

const { Step } = Steps
const { Text } = Typography

/**
 * Download from `./public` folder.
 *
 * @param isJapaneseLang
 */
const downloadTemplate = (isJapaneseLang: boolean) => {
  // Create a template Excel file with the SheetJS libraries:
  // const workbook = utils.book_new()
  // const worksheet = utils.json_to_sheet([])
  // const listAnswer = ['学年', '組', '出席番号']
  // listQuestion.questions.forEach((value, key) => {
  //   listAnswer.push(`Q${key + 1}`)
  // })
  // utils.sheet_add_aoa(worksheet, [listAnswer])
  // utils.sheet_add_json(worksheet, [], { origin: 'A2' })
  // utils.book_append_sheet(workbook, worksheet, listQuestion.name || '中高用')
  // const fileName = `200204_ALPHAアップロード用ファイル-アンケート-${listQuestion.name}.xlsx`
  // writeFile(workbook, fileName, {
  //   bookType: 'xlsx',
  // })

  if (isJapaneseLang) {
    window.open(
      '/upload_sample/ALPHAアップロード用ファイル-アンケート.xlsx',
      '_blank',
    )
  } else {
    window.open(
      '/upload_sample/ALPHA upload excel of Questionnaire.xlsx',
      '_blank',
    )
  }
}

set_cptable(cp_table)

/**
 * Path: questionnaire_upload
 */
const QuestionnaireUpload = () => {
  const { school } = useAlphaStore()
  const { t, i18n } = useTranslation()

  const [disabled, setDisabled] = useState(true)
  const [uploadFile, setUploadFile] = useState<null | undefined | Blob>()
  const [uploading, setUploading] = useState(false)
  const [data, setData] = useState<
    ProcessingUploadQuestionnaireDataRequestStudent[] | undefined
  >()
  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)
  const [hasNullValue, setHasNullValue] = useState(false)

  const [listQuestion, setListQuestion] = useState<{
    name: string
    questions: number[]
  }>({
    name: '中高用',
    questions: [],
  })
  const [totalErrors, setTotalErrors] = useState(0)
  const [questionExtra, setQuestionExtra] = useState<StudentQuestionExtra>({})

  const history = useHistory()

  const isUsingJp = isJapanese(i18n)

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(false)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, confirmed1, confirmed2, confirmed3])

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const onFinish = async () => {
    if (!school || !data) return

    if (listQuestion.questions.length === 0) {
      console.error('question list is empty!')
      return
    }

    setUploading(true)

    // upload backup file
    const schoolId = school._id
    const schoolName = school.attributes.schoolName

    /**
     * Determine whether when this file is uploaded.
     */
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `questionnaire/${schoolName}_${schoolId}_${fileId}_${Date.now()}`

    const questionnaireFormData = new FormData()
    questionnaireFormData.append('file', uploadFile as Blob)
    questionnaireFormData.append('filename', fileId)

    // let fileName: string

    await axios
      .post(
        `${process.env.REACT_APP_REST_API_URL}/alpha/file-upload`,
        questionnaireFormData,
      )
      .then((_res) => {
        // fileName = res.data.data
      })

    // upload questionnaire data
    // Refine data
    for (let i = 0; i < data.length; i++) {
      const { schoolGrade, schoolClass, schoolAttendanceNumber } = data[i]
      data[i] = {
        schoolGrade,
        schoolClass,
        schoolAttendanceNumber,
        questionnaire: {
          ...Object.fromEntries(
            listQuestion.questions.map((_q, index) => {
              let value: number | null = Number(data[i][`q${index + 1}`])
              if (!Number.isFinite(value) || Number.isNaN(value)) {
                value = null
              } else {
                if (
                  _q === 45 &&
                  Number.isFinite(value) &&
                  (value > Object.keys(questionExtra).length || value < 1)
                ) {
                  value = null
                } else if (_q !== 45) {
                  const question = allQuestions.find((q) => q.id === _q)
                  if (
                    question &&
                    (!question.answerType ||
                      question.answerType === AnswerType.options) &&
                    Number.isFinite(value) &&
                    (value > Object.keys(question.options).length || value < 1)
                  ) {
                    value = null
                  }
                }
              }
              return [`q${index + 1}`, value]
            }),
          ),
        },
      }
    }

    try {
      const response = await updateSurvey(data)
      const { status, error } = response.data

      if (status === 200) {
        toast.success(t('アップロードしました。'))
        // form.resetFields()
        setUploadFile(null)
      } else {
        toast.error(t('エラーが発生しました。'))
        console.error('alpha/file-upload - error:', error)
      }
    } catch (err) {
      console.error(err)
      toast.error(t('エラーが発生しました。'))
    } finally {
      setUploading(false)
    }
  }

  const register = async () => {
    await onFinish()
    setRegisterComplete(true)
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      toast.error(t('Excelファイルを選んでください。'))
      return
    }

    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  // Get questions
  useEffect(() => {
    if (school?.attributes?.prefectureCode === undefined) return

    const prefQuestions = getCurrentPrefectureQuestionsWithPrefName(
      school.attributes.prefectureCode,
      school._id,
      isElementarySchool,
    )

    setListQuestion(prefQuestions)
    for (const question of allQuestions) {
      if (prefQuestions.questions.includes(question.id) && question.id === 45) {
        // question is modified later, so copy question information to new object
        const extraQuestion = getExtraQuestion(
          school.attributes.schoolName,
          question,
        )
        if (extraQuestion) {
          setQuestionExtra(extraQuestion)
        }
      }
    }
  }, [school, isElementarySchool])

  const parseXlsx = async (file: RcFile) => {
    const questionsStudents: ProcessingUploadQuestionnaireDataRequestStudent[] =
      []
    const data = read(await file.arrayBuffer())

    const firstSheetName = data.SheetNames[0]
    const workBook = data.Sheets

    if (firstSheetName && workBook) {
      const rows = utils.sheet_to_json(workBook[firstSheetName]) as Record<
        string,
        string | number
      >[]
      let totalErrors = 0
      let errRowIdsString = ''

      let gradeKey: string
      let classKey: string
      let studentNumberKey: string

      if (isUsingJp) {
        gradeKey = '学年'
        classKey = '組'
        studentNumberKey = '出席番号'
      } else {
        gradeKey = 'Grade'
        classKey = 'Class'
        studentNumberKey = 'Number'
      }

      // const maxShowingErr = 3
      // let errCount = 0

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]

        const schoolGradeNumber = getValidPositiveNumberFromExcelFile(
          row[gradeKey],
        )
        const schoolClassNumber = getValidPositiveNumberFromExcelFile(
          row[classKey],
        )
        const schoolAttendanceNumber = getValidPositiveNumberFromExcelFile(
          row[studentNumberKey],
        )

        if (
          !schoolGradeNumber ||
          !schoolClassNumber ||
          !schoolAttendanceNumber
        ) {
          console.error('this row is invalid:', JSON.stringify(row))
          // errCount++
          // if (errCount <= maxShowingErr) {
          //   toast.error(
          //     `${t('行番号{{ids}}に無効なデータがあります', {
          //       ids: `${i + 1}、`,
          //     })}。 [Grade, class or attendance number is not valid.]`,
          //     8,
          //   )
          // }

          errRowIdsString += `${i + 1}、`
          continue
        }

        if (errRowIdsString.length > 0) {
          // remove unnecessary comma characters.
          errRowIdsString = errRowIdsString.substring(
            0,
            errRowIdsString.length - 1,
          )
        }

        questionsStudents.push({
          schoolGrade: schoolGradeNumber,
          schoolClass: schoolClassNumber,
          schoolAttendanceNumber: schoolAttendanceNumber,
          // Q1	Q2	Q3	Q4	Q5	Q6	Q7	Q8	Q9	Q10	Q11
          ...Object.fromEntries(
            // eslint-disable-next-line no-loop-func
            listQuestion.questions.map((_q, index) => {
              const qId = index + 1
              const value = Number(row[`Q${qId}`])
              if (!Number.isFinite(value) || Number.isNaN(value)) {
                totalErrors++
              } else {
                if (
                  _q === 45 &&
                  Number.isFinite(value) &&
                  (value > Object.keys(questionExtra).length || value < 1)
                ) {
                  totalErrors++
                }
                if (_q !== 45) {
                  const question = allQuestions.find((q) => q.id === _q)
                  if (
                    question &&
                    (!question.answerType ||
                      question.answerType === AnswerType.options) &&
                    Number.isFinite(value) &&
                    (value > Object.keys(question.options).length || value < 1)
                  ) {
                    totalErrors++
                  }
                }
              }

              return [`q${qId}`, value]
            }),
          ),
        })
      }
      if (errRowIdsString.length > 0) {
        toast.error(
          t('行番号{{ids}}に無効なデータがあります', {
            ids: errRowIdsString,
          }),
        )
      }
      setData(questionsStudents)
      setTotalErrors(totalErrors)
      setHasNullValue(totalErrors > 0)
    }
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: t('学年'),
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
      className: 'text-center-f',
    },
    {
      title: t('組'),
      dataIndex: 'schoolClass',
      key: 'schoolClass',
      className: 'text-center-f',
    },
    {
      title: t('出席番号'),
      dataIndex: 'schoolAttendanceNumber',
      key: 'schoolAttendanceNumber',
      className: 'text-center-f',
    },
    ...listQuestion.questions.map((_q, index) => ({
      title: `Q${index + 1}`,
      dataIndex: `q${index + 1}`,
      key: `q${index + 1}`,
      className: 'text-center-f',
      render: (_, item) => {
        const key = `q${index + 1}`
        const value = Number(item[key])
        let cellClassName = ''
        const invalidClassName =
          'bg-red-300 border-red-500 border-2 invalid-cell'
        if (!Number.isFinite(value) || Number.isNaN(value)) {
          cellClassName = invalidClassName
        } else {
          if (
            _q === 45 &&
            Number.isFinite(value) &&
            (value > Object.keys(questionExtra).length || value < 1)
          ) {
            cellClassName = invalidClassName
          }
          if (_q !== 45) {
            const question = allQuestions.find((q) => q.id === _q)
            if (
              question &&
              (!question.answerType ||
                question.answerType === AnswerType.options) &&
              Number.isFinite(value) &&
              (value > Object.keys(question.options).length || value < 1)
            ) {
              cellClassName = invalidClassName
            }
          }
        }
        return <div className={cellClassName}>{item[key]}</div>
      },
    })),
  ]

  const tableProps = {
    columns: tableColumns,
    dataSource: data,
    rowKey: 'schoolGrade',
  }

  const steps = [
    {
      title: `①${t('登録用エクセルファイルをダウンロード')}`,
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate(isUsingJp)}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate(isUsingJp)}>
            {t('登録用エクセルファイルをダウンロード')}
          </Button>
        </div>
      ),
    },
    {
      title: `②${t('記入例')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-between pt-5">
          <img
            className="mb-5"
            src={
              isUsingJp
                ? '/questionnaire_list_demo.png'
                : '/questionnaire_list_demo_en.png'
            }
            alt=""
          />
          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            {t('記入例を確認しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: `③${t('測定結果をアップロード')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black">
              {t('①でダウンロードしたファイルをアップロードしてください。')}
            </Text>
          </div>
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file) => {
                parseXlsx(file)
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setUploadFile(null)
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">
                  {t('アンケート結果をアップロード')}
                </Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-36">({t('XLSXファイル式')})</Text>
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            {t('アンケート結果を正しく記入しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: `④${t('確認')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          {totalErrors > 0 && (
            <div className="w-full bg-red-100 text-red-500 p-4">
              回答できない選択肢が入力されており、登録すると未回答（空欄）となります。
              <br />
              Excelファイルを修正して再アップロードするか、登録後に正しい値を入力してください。
            </div>
          )}
          <div className="py-4">
            <QuestionnairePanel noElipssis />
          </div>
          <div className="flex w-full items-center p-2">
            <div className="w-6 h-4 bg-red-300 border-red-500 border-2 mr-1" />
            <div className="text-black">入力エラー個数：{totalErrors}</div>
          </div>
          <Table
            {...tableProps}
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 50,
              position: ['bottomCenter'],
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
          >
            {t('記入したデータは正しいでしょうか？')}
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard
      selectedMenu={undefined}
      navbar={t('アンケート結果をエクセルでアップロード')}
    >
      {!registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '900px' }}>
            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  {t('戻る')}
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  {t('次へ')}
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  {t('登録')}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <QuestionnaireCompletionLayout
          message={t('登録完了')}
          hasNullValue={hasNullValue}
          button={
            <Button
              type="primary"
              onClick={() => {
                history.push(
                  hasNullValue ? '/questionnaire' : '/questionnaire_result',
                )
              }}
            >
              {t(
                hasNullValue
                  ? 'アンケート入力画面に移動する'
                  : 'アンケート結果を閲覧する',
              )}
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default QuestionnaireUpload
