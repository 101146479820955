import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { AUTH_TOKEN_KEY, LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { usePopupStore } from '~/context/popup'
import { useEmailPhoneStore } from '~/context/user'
import { SCHOOL_VERSION_CHECK_KEY } from '~/school-utils/constants'
import { clearLocalStorage } from '~/school-utils/storages'
import { ITeacher } from '~/types/api/alpha/teachers'
import Api from '~/utils/api'
import { CommonError } from '~/utils/error'

const schoolMeApiUrl = '/alpha/v1/school/me'
const popupApiUrl = '/alpha/v1/school/popups'

/**
 * Alpha school API
 */
export const useSchoolMeApiQuery = (): {
  data: ITeacher | undefined
  loading: boolean
  error: CommonError | undefined
  refresh: () => void
} => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  const [data, setData] = useState<ITeacher | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<CommonError | undefined>()

  const fetchedRef = useRef(false)
  const [refresh, setRefresh] = useState(false)
  const { teacher, setTeacher, setSchool } = useAlphaStore()
  const { setPhone, setEmail } = useEmailPhoneStore()
  const { setPopups } = usePopupStore()

  useEffect(() => {
    if (authToken && !fetchedRef.current) {
      // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
      new Promise(async () => {
        try {
          setLoading(true)
          const { data }: { data: ITeacher } = await Api.get(schoolMeApiUrl)
          setData(data)
          localStorage.setItem(
            SCHOOL_VERSION_CHECK_KEY,
            new Date().getTime().toString(),
          )

          if (teacher && teacher.version !== data.version) {
            // Current webApp version is older than server, so have to reload.
            // By default, it performs a normal reload which may use cached
            // resources including JS bundles.
            // The actual caching behavior depends on:
            // - Your server's cache control headers
            // - How your bundler is configured (e.g., if it includes content
            // hashes in filenames)
            setTeacher(data)
            window.location.reload()
          }

          setTeacher(data)

          const schoolInfo = data.school?.attributes
          setPhone(schoolInfo?.telephone)
          setEmail(schoolInfo?.email)

          if (data.school) {
            setSchool(data.school)
          }

          setError(undefined)
          const { data: popups } = await Api.get(popupApiUrl)
          setPopups(popups)
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        } catch (err: any) {
          setError(err)
          console.error(`apiUrl: ${schoolMeApiUrl} - error:`, err)

          if (err.response?.status === 401) {
            clearLocalStorage()
            delete axios.defaults.headers.common[AUTH_TOKEN_KEY]

            // keep this log
            console.log('When response status is unauthorized (401), logout!')
          }
        } finally {
          fetchedRef.current = false
          setLoading(false)
        }
      })
    } else {
      // keep this log
      console.log('Not found auth token when useSchoolMeApiQuery!')
      setLoading(false)
      setError(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, refresh, setTeacher])

  return {
    data: authToken ? data : undefined,
    loading,
    error,
    refresh: () => {
      setRefresh(!refresh)
    },
  }
}
