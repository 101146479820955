import './SchoolYearInfoPage.css'

import {
  SchoolInvitationCode,
  useGradesLazyQuery,
  useStudentInvitationCodesLazyQuery,
} from '@alpha/core'
import { WarningOutlined } from '@ant-design/icons'
import { Button, Select, Table, Typography } from 'antd'
import { ColumnType } from 'antd/es/table'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlphaStore } from '~/context'
import { downloadInvitationCodeExcelFile } from '~/school-utils/download'
import { API_URL } from '~/utils/api'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { Dashboard } from '../layout/Dashboard'
import { QRCodeModal } from '../modal/QRCodeModal'
import type { SchoolYearInfoTable } from './school-year-info/types'

const { Option } = Select
const { Text } = Typography

/**
 * Path: school_year_info
 */
const SchoolYearInfoPage = () => {
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const [grade, setGrade] = useState<number | null>(null)

  const [schoolYearInfoTable, setSchoolYearInfoTable] = useState<
    SchoolYearInfoTable[]
  >([])

  const [count, setCount] = useState<Record<string, number>>({})

  // Class QR Code Modal
  const [showQRModal, setShowQRModal] = useState(false)
  const [qrCodeData, setQRCodeData] = useState<{
    code: SchoolInvitationCode
    data: SchoolYearInfoTable[]
  } | null>(null)

  const [loading, setLoading] = useState(true)

  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [getInvitationCodes, { data: invitationCodesData }] =
    useStudentInvitationCodesLazyQuery({
      fetchPolicy: 'network-only',
    })

  const fetchCount = async (
    schoolId: string,
    testYear: number,
    schoolGrade: number | null,
  ) => {
    await axios
      .get(`${API_URL}/alpha/students`, {
        params: {
          schoolId,
          testYear,
          schoolGrade,
        },
      })
      .then((response) => {
        setCount(response.data.count)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  // getGrades
  useEffect(() => {
    if (!school) {
      return
    }
    const schoolId = school._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear: currentYear,
        },
      },
    })

    fetchCount(schoolId, currentYear, grade)
  }, [school, getGrades, grade])

  // getInvitationCodes
  useEffect(() => {
    if (!gradesData?.grades?.data) return

    const schoolId = school?._id
    if (!schoolId) return

    let schoolGrades = gradesData.grades.data.map((g) => {
      return {
        grade: g.schoolGrade,
        classes: g.numberOfClasses,
      }
    })
    if (grade)
      schoolGrades = schoolGrades.filter(
        (schoolGrade) => schoolGrade.grade === grade,
      )

    getInvitationCodes({
      variables: {
        input: {
          schoolId,
          testYear: currentYear,
          schoolGrades,
        },
      },
    })
  }, [gradesData, school, getInvitationCodes, grade])

  // combineData
  useEffect(() => {
    if (!gradesData?.grades?.data) return
    if (!invitationCodesData?.studentInvitationCodes) return

    setLoading(true)

    const getNumberOfClasses = (_grade: number) =>
      Object.keys(count).filter((key) => key.startsWith(`${_grade}_`)).length

    const getClassCountKeys = (_grade: number) =>
      Object.keys(count)
        .filter((key) => key.startsWith(`${_grade}_`))
        .sort(
          (a, b) =>
            Number.parseInt(a.split('_')[1]) - Number.parseInt(b.split('_')[1]),
        )

    const _data: SchoolYearInfoTable[] = []

    gradesData.grades.data.forEach((gradeData) => {
      const numberOfClasses = getNumberOfClasses(gradeData.schoolGrade)

      if (numberOfClasses > 0) {
        _data.push({
          ...gradeData,
          numberOfClasses,
          // @ts-ignore
          invitationCodes:
            invitationCodesData.studentInvitationCodes?.data?.filter(
              (c) => c.schoolGrade === gradeData.schoolGrade,
            ) ?? [],

          classes: getClassCountKeys(gradeData.schoolGrade).map((key) => {
            const schoolClass = key.split('_')[1]

            /**
             * Property name is data indexes of table column.
             *
             * Excel column:
             * 学年, 組, 出席番号, 姓, 名, サインインコード
             */
            return {
              ...gradeData,
              class: schoolClass,
              numberOfStudentsPerClass: count[key],
              invitationCodes:
                invitationCodesData.studentInvitationCodes?.data?.filter(
                  (c) =>
                    c.schoolGrade === gradeData.schoolGrade &&
                    c.schoolClass === Number.parseInt(schoolClass),
                ) ?? [],
            }
          }),
        })
      }
    })

    setSchoolYearInfoTable(_data)
    setLoading(false)
  }, [count, grade, gradesData, invitationCodesData])

  const handleShowCodes = (code: SchoolInvitationCode) => {
    setShowQRModal(true)
    setQRCodeData({ code, data: schoolYearInfoTable })
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const tableColumns: ColumnType<any>[] = [
    {
      title: t('学年'),
      dataIndex: 'schoolGrade',
      className: 'text-center-f w-1/5',
      onCell: (_, index) => {
        if (index === 0) {
          return { rowSpan: 100 }
        }
        return { rowSpan: 0 }
      },
    },
    {
      title: t('組'),
      dataIndex: 'class',
      className: 'text-center-f w-1/5',
    },
    {
      title: t('生徒数', { student: isElementarySchool ? '児童' : '生徒' }),
      dataIndex: 'numberOfStudentsPerClass',
      className: 'text-center-f w-1/5',
    },
    {
      title: t('サインインコード'),
      dataIndex: 'invitationCodes',
      className: 'text-center-f w-1/5',
      render: (invitationCodes: SchoolInvitationCode[]) => {
        return (
          <div>
            {invitationCodes.map((code, index: number) => (
              <div
                key={`${code.schoolGrade}-${index}`}
                className="flex space-x-2 justify-center"
              >
                <Button
                  type="primary"
                  className="ant-btn-xs"
                  onClick={() => {
                    handleShowCodes(code)
                  }}
                >
                  {t('表示')}
                </Button>
              </div>
            ))}
          </div>
        )
      },
    },

    {
      title: t('コード一括ダウンロード'),
      dataIndex: 'invitationCodes',
      className: 'text-center-f w-1/5',

      render: (invitationCodes: SchoolInvitationCode[]) => {
        return (
          <div>
            {invitationCodes.map((codeInfo, index: number) => (
              <div
                key={`${codeInfo.schoolGrade}-${index}`}
                className="flex justify-center"
              >
                <Button
                  type="primary"
                  className={`ant-btn-xs students-codes-excel-btn${
                    isUsingJp ? '' : ' students-codes-excel-btn-eng'
                  }`}
                  onClick={() => {
                    downloadInvitationCodeExcelFile(
                      codeInfo.schoolGrade,
                      codeInfo.schoolClass,
                      codeInfo.students,
                      codeInfo.code,
                      t,
                    )
                  }}
                >
                  {t('Excel出力')}
                </Button>
              </div>
            ))}
          </div>
        )
      },
    },
  ]

  return (
    <Dashboard
      selectedMenu={3}
      navbar={t(
        isElementarySchool ? '児童のログイン管理' : '生徒のログイン管理',
      )}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="mt-16 students-table-container">
          <div className="space-x-4 mb-4">
            <Select
              className="w-30 rounded-5px"
              value={grade}
              dropdownAlign={{
                offset: [0, -2],
              }}
              onChange={setGrade}
            >
              <Option value={null} key="all">
                {t('全学年')}
              </Option>

              {gradesData?.grades?.data?.map((element) => (
                <Option value={element.schoolGrade} key={element.schoolGrade}>
                  {t(`${element.schoolGrade}学年`)}
                </Option>
              ))}
            </Select>
          </div>

          {loading ? (
            <Table
              columns={tableColumns}
              rowKey={'grade'}
              pagination={{
                hideOnSinglePage: true,
              }}
              loading={loading}
              className="mb-3"
              size="small"
              bordered={true}
            />
          ) : schoolYearInfoTable?.length ? (
            schoolYearInfoTable
              .map((_data) => _data.classes)
              .map((_class, key) => (
                <Table
                  key={key}
                  columns={tableColumns}
                  pagination={{
                    hideOnSinglePage: true,
                  }}
                  dataSource={_class}
                  className="mb-3"
                  size="small"
                  rowKey={(record) => `${record.schoolGrade}_${record.class}`}
                  rowClassName="font-bold text-black"
                  bordered={true}
                />
              ))
          ) : (
            <div className="mt-52 flex text-center items-center justify-center">
              <div className="h-14 w-64 border p-3 border-warn">
                <WarningOutlined className="text-3xl warn-icon" />
                <Text type="danger" className="font-black">
                  {t('生徒登録をしてください。')}
                </Text>
              </div>
            </div>
          )}

          <QRCodeModal
            data={
              qrCodeData as {
                code: SchoolInvitationCode
                data: SchoolYearInfoTable[]
              }
            }
            showModal={showQRModal}
            setShowModal={setShowQRModal}
          />
        </div>

        <div className="text-black mt-4 font-bold text-center w-624px">
          ※
          {t(
            'SSOでのログインを行う場合は、児童生徒用ALPHAのサインイン画面にアクセス後、ログイン方法にて「Googleで続行」もしくは「Microsoftで続行」を選択してください。',
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default SchoolYearInfoPage
