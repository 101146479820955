export const LOCAL_STORAGE_SCHOOL_USER_KEY = 'school-user'

/**
 * School email phone
 */
export const LOCAL_STORAGE_EMAIL_PHONE_KEY = 'email-phone'

/**
 * School webApp version check time
 */
export const SCHOOL_VERSION_CHECK_KEY = 'last_version_check_time'

/**
 * 6 * 60 * 60 * 1000 = 6 hours in milliseconds
 * For example, 00:00 AM to 06:00 AM of the next day, check once.
 */
export const VERSION_CHECKING_INTERVAL = 21600000
