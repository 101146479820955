import { useEffect, useState } from 'react'
import {
  Select,
  Table,
  Button,
  Upload,
  message,
  Checkbox,
  Steps,
  Typography,
} from 'antd'
import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'

import { Dashboard } from '../layout/Dashboard'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import { uploadStudentsByTeacher } from '~/components/admin/api'
import CompletionLayout from '../layout/CompletionLayout'
import { t } from 'i18next'
import toast from 'react-hot-toast'
import axios from 'axios'
import { excelToJson } from '~/utils/exel'
import { RcFile } from 'antd/lib/upload'
import { getValidGenderFromJpText } from '~/utils'

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

const displayNumbers = [
  { text: '20　件/ページ', value: 20 },
  { text: '50　件/ページ', value: 50 },
  { text: '100　件/ページ', value: 100 },
]

const StudentBatchUploadPage = () => {
  const { teacher, school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const [disabled, setDisabled] = useState(true)

  const [uploading, setUploading] = useState(false)

  const [data, setData] = useState<any[]>([])

  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [listBeforeUpload, setListBeforeUpload] = useState<any>([])
  const [downloaded, setDownloaded] = useState(false)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)
  const [pageSize, setPageSize] = useState(50)
  const [uploadFile, setUploadFile] = useState<RcFile | null>()
  const [current, setCurrent] = useState<number>(1)

  const history = useHistory()

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(!downloaded)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, downloaded, confirmed1, confirmed2, confirmed3])

  const keyInfo = {
    姓: 'familyName',
    名: 'givenName',
    せい: 'familyNameHiragana',
    めい: 'givenNameHiragana',
    GIGAID: 'username',
    学年: 'schoolGrade',
    組: 'schoolClass',
    出席番号: 'attendanceNumber',
    性別: 'gender',
    '生年月日（年）': 'yearBirth',
    '生年月日（月）': 'monthBirth',
    '生年月日（日）': 'dayBirth',
  }

  const handleUpload = async () => {
    /**
     * Determine whether when this file is uploaded.
     */
    // upload backup file
    if (!school) {
      toast.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('when call alpha/file-upload, error: school is not found')
      return false
    }

    setUploading(true)
    const schoolId = school._id
    const schoolName = school.attributes.schoolName
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `student_list/${schoolName}_${schoolId}_${fileId}_${Date.now()}`
    const studentListFormData = new FormData()
    studentListFormData.append('file', uploadFile as any)
    studentListFormData.append('filename', fileId)
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_REST_API_URL}/alpha/file-upload`,
        studentListFormData,
      )
      const rawData = await excelToJson(data.data)

      const processData = rawData.map((obj: any) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            keyInfo[key] ?? key,
            value,
          ]),
        ),
      )
      setData(processData)
      setListBeforeUpload(processData)
    } catch (e) {
      message.error('エラーが発生しました')
      console.log(e)
    }
    finally {
      setUploading(false)
    }
  }

  const changePageSize = (value) => {
    setCurrent(1)
    setPageSize(value)
  }

  const register = async () => {
    if (!teacher) {
      message.error(
        'エラーが発生しました。しばらくしてからもう一回お試しください。',
      )
      return
    }
    for (const student of listBeforeUpload) {
      student.gender = getValidGenderFromJpText(student.gender) ?? ''
    }
    await uploadStudentsByTeacher(listBeforeUpload)
    setRegisterComplete(true)
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      message.error('Excelファイルを選択してください')
      return
    }
    if (nextStepIdx === 3 && uploadFile) {
      await handleUpload()
    }
    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setConfirmed1(false)
    setConfirmed2(false)
    setConfirmed3(false)
    setUploadFile(undefined)
    setCurrentStepIdx(currentStepIdx - 1)
  }

  const downloadTemplate = () => {
    window.open(
      '/upload_sample/200204_KOBE_ALPHAアップロード用ファイル-生徒登録.xlsx',
      '_blank',
    )
    setDownloaded(true)
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
      className: 'text-center-f',
    },
    {
      title: '組',
      dataIndex: 'schoolClass',
      key: 'schoolClass',
      className: 'text-center-f',
    },
    {
      title: '出席番号',
      dataIndex: 'attendanceNumber',
      key: 'attendanceNumber',
      className: 'text-center-f',
    },
    {
      title: 'GIGAID',
      dataIndex: 'username',
      key: 'username',
      className: 'text-center-f',
    },
    {
      title: '姓',
      dataIndex: 'familyName',
      key: 'familyName',
      className: 'text-center-f',
    },
    {
      title: '名',
      dataIndex: 'givenName',
      key: 'givenName',
      className: 'text-center-f',
    },
    {
      title: 'せい',
      dataIndex: 'familyNameHiragana',
      key: 'familyNameHiragana',
      className: 'text-center-f',
    },
    {
      title: 'めい',
      dataIndex: 'givenNameHiragana',
      key: 'givenNameHiragana',
      className: 'text-center-f',
    },
    {
      title: '性別',
      dataIndex: 'gender',
      key: 'gender',
      className: 'text-center-f',
    },
    {
      title: '生年月日（年）',
      dataIndex: 'yearBirth',
      key: 'yearBirth',
      className: 'text-center-f',
    },
    {
      title: '生年月日（月）',
      dataIndex: 'monthBirth',
      key: 'monthBirth',
      className: 'text-center-f',
    },
    {
      title: '生年月日（日）',
      dataIndex: 'dayBirth',
      key: 'dayBirth',
      className: 'text-center-f',
    },
  ]

  const tableData = data.filter(
    (inf) =>
      inf.username &&
      inf.gender &&
      Number(inf.schoolClass) &&
      Number(inf.schoolGrade) &&
      Number(inf.attendanceNumber) &&
      ((Number(inf.yearBirth) && Number(inf.monthBirth) && Number(inf.dayBirth)) ||
      (!inf.yearBirth && !inf.monthBirth && !inf.dayBirth)),
  )
  const tableProps = {
    columns: tableColumns,
    dataSource: tableData.map((info, index) => {
      return {
        ...info,
        username: !Number(info.username)
          ? info.username.toLowerCase()
          : info.username,
        key: index,
      }
    }),
    rowKey: 'ID',
  }

  const steps = [
    {
      title: '①登録用エクセルファイルを\nダウンロード',
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate()}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate()}>
            登録用エクセルファイルをダウンロード
          </Button>
        </div>
      ),
    },
    {
      title: '②記入例',
      content: (
        <div className="steps-content flex flex-col items-center justify-between pt-5">
          <img
            className="mb-5"
            width={800}
            src="/student_list_demo.png"
            alt=""
          />
          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            記入例を確認しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: '③生徒情報をアップロード',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black">
              ①でダウンロードしたファイルをアップロードしてください。
            </Text>
          </div>
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file: RcFile) => {
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setUploadFile(null)
                setData([])
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">生徒情報をアップロード</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-36">(XLSXファイル式)</Text>
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            生徒情報を正しく記入しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: ' ④確認',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="mb-5 w-full flex justify-start">
            <Select
              size="small"
              className="w-30 rounded-5px"
              placeholder="表示数"
              value={pageSize}
              dropdownAlign={{
                offset: [0, -2], // align offset
              }}
              onChange={changePageSize}
            >
              {displayNumbers?.map((d) => (
                <Option className="option-small" value={d.value} key={d.text}>
                  {d.text}
                </Option>
              ))}
            </Select>
          </div>
          <Table
            {...tableProps}
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              defaultPageSize: 50,
              pageSize: pageSize,
              position: ['bottomCenter'],
              current: current,
              onChange(current) {
                setCurrent(current)
              },
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
            disabled={data?.length === 0}
          >
            記入したデータは正しいでしょうか？
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard
      selectedMenu={3}
      navbar={t(isElementarySchool ? '児童情報の登録' : '生徒情報の登録')}
    >
      {!registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '680px' }}>
            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  戻る
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  次へ
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  登録
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CompletionLayout
          message="登録完了"
          button={
            <Button
              type="primary"
              onClick={() => history.push('/students')}
            >
              IDを見に行く
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default StudentBatchUploadPage
