import { Form, Row } from 'antd'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import { useSchoolMeApiQuery } from '~/hooks/useSchoolMeApiQuery'
import { cloneDeep } from '~/school-utils/clone'
import Api from '~/utils/api'
import {
  countRequiredQuestionsLength,
  getCurrentPrefectureQuestions,
} from '~/utils/questions'
import SchoolHeader from '../cards/SchoolHeader'
import { Dashboard } from '../layout/Dashboard'
import ScreenLoading from '../shared/loading/ScreenLoading'

interface IStudentStatisticBase {
  schoolGrade: number
  totalTestStudents: number
}

interface IHomeStatistic {
  totalRegisterStudent: {
    schoolGrade: number
    totalStudents: number
  }[]
  totalTestStudent: IStudentStatisticBase[]
  totalQuestionStudent: {
    schoolGrade: number
    totalQuestionStudents: number
  }[]

  isAllNotRequired?: boolean
}

interface IHomeStatisticTable {
  /**
   * @example 全学年
   */
  schoolGrade: number | string

  /**
   * @example 全学年
   */
  schoolGradeName: number | string

  /**
   * 入力済
   */
  totalTestStudents: number
  /**
   * 入力済
   */
  totalQuestionStudents: number
  totalStudents: number
}

const HomePage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [dataTable, setDataTable] = useState<IHomeStatisticTable[]>()
  const [isLoading, setIsLoading] = useState(false)
  const { school, teacher } = useAlphaStore()

  const prefectureCode = school?.attributes?.prefectureCode

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const getStudentData = async (questionLength: number) => {
    setIsLoading(true)
    const apiUrl = `/alpha/v1/school/home-statistics?questionLength=${questionLength}`

    const { data } = await Api.get<IHomeStatistic>(apiUrl)
    setIsLoading(false)

    if (!data) {
      toast.error(t('エラーが発生しました。'))
      console.error('data:', data)
      return
    }

    const totalRegisterStudent = cloneDeep(data.totalRegisterStudent)

    const questionData = formatData(
      cloneDeep(data.totalQuestionStudent),
      'schoolGrade',
    )

    if (data.totalTestStudent.length === 0) {
      if (data.isAllNotRequired) {
        // All tests is NOT required to count!
        // So inputted students number is total number of all students.

        for (const item of totalRegisterStudent) {
          data.totalTestStudent.push({
            schoolGrade: item.schoolGrade as number,
            totalTestStudents: item.totalStudents,
          })
        }
      } else {
        // All tests is required to count!
        // This is default behavior!
        // So, when totalTestStudent is empty array, it's value is 0 each grade.

        for (const item of totalRegisterStudent) {
          data.totalTestStudent.push({
            schoolGrade: item.schoolGrade as number,
            totalTestStudents: 0,
          })
        }
      }
    }

    const testData = formatData(cloneDeep(data.totalTestStudent), 'schoolGrade')

    const dataResult: IHomeStatisticTable[] = []
    let totalRegisters = 0
    let totalQuestions = 0
    let totalTests = 0

    totalRegisterStudent.forEach((item) => {
      totalRegisters += item.totalStudents

      const currentTotalQuestionStudentsNumber =
        questionData[item.schoolGrade]?.totalQuestionStudents ?? 0

      totalQuestions += currentTotalQuestionStudentsNumber

      const currentTotalTestStudentsNumber =
        testData[item.schoolGrade]?.totalTestStudents ?? 0

      totalTests += currentTotalTestStudentsNumber

      dataResult.push({
        ...item,
        schoolGradeName: `${item.schoolGrade}学年`,
        totalQuestionStudents: currentTotalQuestionStudentsNumber,
        totalTestStudents: currentTotalTestStudentsNumber,
      })
    })

    // Sum at last row.
    dataResult.push({
      schoolGrade: '全学年',
      schoolGradeName: '全学年',
      totalStudents: totalRegisters,
      totalQuestionStudents: totalQuestions,
      totalTestStudents: totalTests,
    })

    setDataTable(dataResult)
  }

  const formatData = (list, key) => {
    const result = {}
    list.forEach((value) => {
      result[value[key]] = value
    })
    return result
  }

  useEffect(() => {
    if (!prefectureCode) return

    const questionList = getCurrentPrefectureQuestions(
      prefectureCode,
      school._id,
      isElementarySchool,
      school.attributes.schoolCategoryCode,
    )

    getStudentData(countRequiredQuestionsLength(questionList))

    // TODO
    // updateWorkbookFromPublic(t, 'report/集計結果_中学校用')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElementarySchool, prefectureCode])

  const { data: teacherData, loading: teacherLoading } = useSchoolMeApiQuery()

  const [form] = Form.useForm()

  useEffect(() => {
    if (teacherLoading) return
    if (!teacherData) {
      return
    }

    const _school = teacherData.school

    if (_school) {
      form.setFieldsValue({
        email: _school.attributes.email,
        telephone: _school.attributes.telephone,
        isStudentInputActive: _school.attributes.isStudentInputActive
          ? '1'
          : '0',
      })
    }
  }, [teacherLoading, teacherData, form])

  return (
    <>
      {school && teacher ? (
        <Dashboard selectedMenu={1} navbar={t('ホーム')}>
          <>
            <SchoolHeader school={school} teacher={teacher} />

            <Row className="w-full justify-center" style={{ padding: '12px' }}>
              <div className="flex justify-between">
                <div>
                  <table className="table-static-home">
                    <thead>
                      <tr>
                        <th colSpan={2} />
                        <th colSpan={2} className="px-1 pb-1">
                          {t('体力テスト')}
                        </th>
                        <th colSpan={2} className="px-1 pb-1">
                          {t('アンケート')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-header">
                        <td className="school-grade-name">{t('学年')}</td>
                        <td className="border-right">
                          <span>{t('在籍人数')}</span>
                        </td>
                        <td>
                          <span className="color-success">{t('入力済')}</span>
                        </td>
                        <td className="border-right">
                          <span className="color-red">{t('未入力')}</span>
                        </td>
                        <td>
                          <span className="color-success">{t('入力済')}</span>
                        </td>
                        <td>
                          <span className="color-red">{t('未入力')}</span>
                        </td>
                      </tr>
                      {dataTable?.map((item, index) => (
                        <tr className="table-body" key={index}>
                          <td>{t(`${item.schoolGradeName}_`)}</td>
                          <td className="border-right">
                            <span>{item.totalStudents}</span>
                          </td>
                          <td>
                            <span className="color-success">
                              {item.totalTestStudents}
                            </span>
                          </td>
                          <td className="border-right">
                            <span className="color-red">
                              {item.totalStudents - item.totalTestStudents}
                            </span>
                          </td>
                          <td>
                            <span className="color-success">
                              {item.totalQuestionStudents}
                            </span>
                          </td>
                          <td>
                            <span className="color-red">
                              {item.totalStudents - item.totalQuestionStudents}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="ml-4">
                  <div className="flex justify-between home-menu">
                    <div className="flex">
                      <div className="icon-number">1</div>

                      <div className="image-menu">
                        <img src="solid_children.png" alt="" />
                      </div>

                      <div className="home-menu-content">
                        <span className="home-menu-title">
                          {t('生徒登録をしよう', {
                            student: isElementarySchool ? '児童' : '生徒',
                          })}
                        </span>
                        <span>
                          {t('「情報」から「生徒登録」を選択。', {
                            student: isElementarySchool ? '児童' : '生徒',
                          })}
                          <br />
                          {t('Excelファイルをダウンロードして登録しましょう。')}
                        </span>
                      </div>
                    </div>

                    <div
                      className="home-menu-link"
                      onClick={() => history.push('/student_list')}
                    >
                      <span>
                        {t('生徒を登録', {
                          student: isElementarySchool ? '児童' : '生徒',
                        })}
                      </span>
                      <img src="next_blue.png" alt="" />
                    </div>
                  </div>

                  <div className="flex justify-between home-menu">
                    <div className="flex">
                      <div className="icon-number">2</div>
                      <div className="image-menu">
                        <img src="qr-code-line.png" alt="" />
                      </div>
                      <div className="home-menu-content">
                        <span className="home-menu-title">
                          {t('サインインコードを印刷しよう')}
                        </span>
                        <span>
                          {t('サインインコードを印刷して配りましょう。')}
                        </span>
                      </div>
                    </div>

                    <div
                      className="home-menu-link"
                      onClick={() => history.push('/school_year_info')}
                    >
                      <span>{t('サインインコードを表示')}</span>
                      <img src="next_blue.png" alt="" />
                    </div>
                  </div>

                  <div className="flex justify-between home-menu">
                    <div className="flex">
                      <div className="icon-number">3</div>
                      <div className="image-menu">
                        <img src="run_home.png" alt="" />
                      </div>
                      <div className="home-menu-content">
                        <span className="home-menu-title">
                          {t('体力テストの結果を登録しよう')}
                        </span>
                        <span>
                          {t('「入力」から「体力テスト」を選択。')}
                          <br />
                          {t(
                            '生徒ページでの入力、Excelファイルでの登録も可能です。',
                            {
                              student: isElementarySchool ? '児童' : '生徒',
                            },
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="home-menu-link"
                      onClick={() => history.push('/input')}
                    >
                      <span>{t('体力テストを入力')}</span>
                      <img src="next_blue.png" alt="" />
                    </div>
                  </div>
                  <div className="flex justify-between home-menu">
                    <div className="flex">
                      <div className="icon-number">4</div>
                      <div className="image-menu">
                        <img src="survey.png" alt="" />
                      </div>
                      <div className="home-menu-content">
                        <span className="home-menu-title">
                          {t('アンケート結果を登録しよう')}
                        </span>
                        <span>
                          {t('「入力」から「アンケート」を選択。')}
                          <br />
                          {t(
                            '生徒ページでの入力、Excelファイルでの登録も可能です。',
                            {
                              student: isElementarySchool ? '児童' : '生徒',
                            },
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="home-menu-link"
                      onClick={() => history.push('/input')}
                    >
                      <span>{t('アンケートを入力')}</span>
                      <img src="next_blue.png" alt="" />
                    </div>
                  </div>
                  <div className="flex justify-between home-menu">
                    <div className="flex">
                      <div className="icon-number">5</div>
                      <div className="image-menu">
                        <img src="collection-play-fill.png" alt="" />
                      </div>
                      <div className="home-menu-content">
                        <span className="home-menu-title">
                          {t('動画コンテンツを視聴しよう')}
                        </span>
                        <span>
                          {t('「運動コンテンツ」、「運動プログラム」と')}
                          <br />
                          {t(
                            '「体力テストお手本動画」を視聴することができます。',
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="home-menu-link"
                      onClick={() => history.push('/movie')}
                    >
                      <span>{t('動画コンテンツを視聴')}</span>
                      <img src="next_blue.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <ScreenLoading isLoading={isLoading} />
          </>
        </Dashboard>
      ) : (
        <Dashboard selectedMenu={1} navbar={t('ホーム')}>
          <div />
        </Dashboard>
      )}
    </>
  )
}

export default HomePage
