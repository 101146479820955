import toast from 'react-hot-toast'

// Element school:
// ▼小学校	入力可能範囲	"要確認表示範囲（下側）"	"要確認表示範囲（上側）"
// 身長	80~220	無し	200~220
// 体重	10~150	無し	100~150
// 握力	0~99	0~3	50~99
// 上体起こし	0~70	0~3	40~70
// 長座体前屈	1~99	0~6	70~99
// 反復横跳び	1~99	0~12	70~99
// 20mシャトルラン	0~247	0~7	130~247
// 50ｍ走	5.0~60.0	0~6.5	20~60
// 立ち幅跳び	1~399	0~50	250~399
// ボール投げ	0~99	0~1	60~99

// Junior and high school:
// ▼中・高校	入力可能範囲	"要確認表示範囲（下側）"	"要確認表示範囲（上側）"
// 身長	80-220	80~100	200~220
// 体重	10-150	無し	無し
// 握力	0-99	0~5	70~99
// 上体起こし	0-70	0~5	50~70
// 長座体前屈	1-99	0~9	80~99
// 反復横跳び	1-99	0~9	70~99
// 持久走（分）	1-30	0~2	18~30
// 持久走（秒）	0-59	無し	無し
// 20mシャトルラン	0-247	0~10	150~247
// 50ｍ走	5.0-60.0	0~6.0	20~99
// 立ち幅跳び	1-399	0~50	300~399
// ボール投げ	0-99	0~2	50~99

/**
 * Highlight values ​​that are suspected to be typos
 */
interface TestItemRange {
  // inputRange: {
  //   min: number
  //   max: number
  // }
  // warningRange: {
  lower?: {
    min: number
    max: number
  }
  upper?: {
    min: number
    max: number
  }
}

export const elementarySchoolRanges: Record<
  | 'height'
  | 'weight'
  | 'grip'
  | 'sitUps'
  | 'bending'
  | 'sideJump'
  | 'shuttleRun'
  | 'sprintRun'
  | 'standingJump'
  | 'handballThrow',
  TestItemRange
> = {
  height: {
    // inputRange: { min: 80, max: 220 },
    // warningRange: {
    upper: { min: 200, max: 220 },
    // },
  },
  weight: {
    // inputRange: { min: 10, max: 150 },
    // warningRange: {
    upper: { min: 100, max: 150 },
  },
  grip: {
    // inputRange: { min: 0, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 3 },
    upper: { min: 50, max: 99 },
  },
  sitUps: {
    // inputRange: { min: 0, max: 70 },
    // warningRange: {
    lower: { min: 0, max: 3 },
    upper: { min: 40, max: 70 },
  },
  bending: {
    // inputRange: { min: 1, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 6 },
    upper: { min: 70, max: 99 },
  },
  sideJump: {
    // inputRange: { min: 1, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 12 },
    upper: { min: 70, max: 99 },
  },
  shuttleRun: {
    // inputRange: { min: 0, max: 247 },
    // warningRange: {
    lower: { min: 0, max: 7 },
    upper: { min: 130, max: 247 },
  },
  sprintRun: {
    // inputRange: { min: 5, max: 180 },
    // warningRange: {
    lower: { min: 0, max: 6.5 },
    upper: { min: 20, max: 180 },
  },
  standingJump: {
    // inputRange: { min: 1, max: 399 },
    // warningRange: {
    lower: { min: 0, max: 50 },
    upper: { min: 250, max: 399 },
  },
  handballThrow: {
    // inputRange: { min: 0, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 1 },
    upper: { min: 60, max: 99 },
  },
}

export const secondarySchoolRanges: Record<
  | 'height'
  | 'weight'
  | 'grip'
  | 'sitUps'
  | 'bending'
  | 'sideJump'
  | 'shuttleRun'
  | 'sprintRun'
  | 'standingJump'
  | 'handballThrow'
  | 'enduranceRunMinutes'
  | 'enduranceRunSeconds',
  TestItemRange
> = {
  height: {
    // inputRange: { min: 80, max: 220 },
    // warningRange: {
    lower: { min: 80, max: 100 },
    upper: { min: 200, max: 220 },
  },
  weight: {
    // inputRange: { min: 10, max: 150 },
    // warningRange: {},
  },
  grip: {
    // inputRange: { min: 0, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 5 },
    upper: { min: 70, max: 99 },
  },
  sitUps: {
    // inputRange: { min: 0, max: 70 },
    // warningRange: {
    lower: { min: 0, max: 5 },
    upper: { min: 50, max: 70 },
  },
  bending: {
    // inputRange: { min: 1, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 9 },
    upper: { min: 80, max: 99 },
  },
  sideJump: {
    // inputRange: { min: 1, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 9 },
    upper: { min: 70, max: 99 },
  },
  enduranceRunMinutes: {
    // inputRange: { min: 1, max: 30 },
    // warningRange: {
    lower: { min: 0, max: 2 },
    upper: { min: 18, max: 30 },
  },
  enduranceRunSeconds: {
    // inputRange: { min: 0, max: 59 },
    // warningRange: {},
  },
  shuttleRun: {
    // inputRange: { min: 0, max: 247 },
    // warningRange: {
    lower: { min: 0, max: 10 },
    upper: { min: 150, max: 247 },
  },
  sprintRun: {
    // inputRange: { min: 5.0, max: 60.0 },
    // warningRange: {
    lower: { min: 0, max: 6 },
    upper: { min: 20, max: 99 },
  },
  standingJump: {
    // inputRange: { min: 1, max: 399 },
    // warningRange: {
    lower: { min: 0, max: 50 },
    upper: { min: 300, max: 399 },
  },
  handballThrow: {
    // inputRange: { min: 0, max: 99 },
    // warningRange: {
    lower: { min: 0, max: 2 },
    upper: { min: 50, max: 99 },
  },
}

/**
 * Check if a value is in the warning range
 */
export const checkIfIsInWarningRange = (
  itemKey: string,
  value: number | null | undefined,
  isElementarySchool: boolean,
): boolean => {
  if (value == null) return false

  const ranges = isElementarySchool
    ? elementarySchoolRanges
    : secondarySchoolRanges

  let subItemKey: string

  switch (itemKey) {
    case 'height':
    case 'weight':
    case 'grip':
    case 'sitUps':
    case 'bending':
    case 'sideJump':
    case 'shuttleRun':
    case 'sprintRun':
    case 'standingJump':
    case 'handballThrow':
    case 'enduranceRunMinutes':
    case 'enduranceRunSeconds':
      subItemKey = itemKey
      break

    case 'gripRight1':
    case 'gripRight2':
    case 'gripLeft1':
    case 'gripLeft2':
      subItemKey = 'grip'
      break

    case 'bending1':
    case 'bending2':
      subItemKey = 'bending'
      break

    case 'sideJump1':
    case 'sideJump2':
      subItemKey = 'sideJump'
      break

    case 'shuttleRunCount':
      subItemKey = 'shuttleRun'
      break

    case 'sprintRunSeconds':
      subItemKey = 'sprintRun'
      break

    case 'sprintRunCount':
      subItemKey = 'sprintRun'
      break

    case 'runningTime':
      subItemKey = 'enduranceRunMinutes'
      break
    case 'runningTimeSeconds':
      subItemKey = 'enduranceRunSeconds'
      break

    case 'handballThrow1':
    case 'handballThrow2':
      subItemKey = 'handballThrow'
      break

    case 'standingJump1':
    case 'standingJump2':
      subItemKey = 'standingJump'
      break

    default:
      toast.error(`不明な項目名: ${itemKey}`)
      throw new Error(`不明な項目名: ${itemKey}`)
  }

  const range = ranges[subItemKey] as TestItemRange
  if (!range) return false

  const { lower, upper } = range

  if (lower && value >= lower.min && value <= lower.max) {
    return true
  }

  if (upper && value >= upper.min && value <= upper.max) {
    return true
  }

  return false
}

/**
 * Get the warning type for a value
 */
// export const getWarningType = (
//   itemKey: string,
//   value: number,
//   isElementarySchool: boolean,
// ): 'lower' | 'upper' | null => {
//   const ranges = isElementarySchool
//     ? elementarySchoolRanges
//     : secondarySchoolRanges
//   const range = ranges[itemKey]
//   if (!range) return null

//   const { warningRange } = range
//   const { lower, upper } = warningRange

//   if (lower && value >= lower.min && value <= lower.max) {
//     return 'lower'
//   }

//   if (upper && value >= upper.min && value <= upper.max) {
//     return 'upper'
//   }

//   return null
// }
