import { STORAGE_URL } from "~/utils/constants"

export const exerciseVideos = [
  {
    _id: '6416d1f801cb57ff84a3386f',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/021_%E3%82%BF%E3%82%AA%E3%83%AB%E7%B6%B1%E5%BC%95%E3%81%8D.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/021.mp4`,

    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/021_%E3%82%BF%E3%82%AA%E3%83%AB%E7%B6%B1%E5%BC%95%E3%81%8D.webp`,
    desc: '',
    title: 'タオル綱引き',
    sportName: '握力',
    ability: ['握力', '調整力'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a33870',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/002_%E4%BA%BA%E9%96%93%E7%9F%A5%E6%81%B5%E3%81%AE%E8%BC%AA.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/002.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/002_%E4%BA%BA%E9%96%93%E7%9F%A5%E6%81%B5%E3%81%AE%E8%BC%AA.webp`,
    desc: '',
    title: '人間知恵の輪',
    sportName: '上体起こし',
    ability: ['筋力', '柔軟性', '協調性'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a3387c',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/008_%E7%88%86%E5%BC%BE%E3%82%B1%E3%82%99%E3%83%BC%E3%83%A0.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/008.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/008_%E7%88%86%E5%BC%BE%E3%82%B1%E3%82%99%E3%83%BC%E3%83%A0.webp`,
    desc: '',
    title: '爆弾ゲーム',
    sportName: 'ボール投げ',
    ability: ['巧緻性', '瞬発力'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a33874',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/004_%E3%81%8B%E3%81%8B%E3%81%97.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/004.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/004_%E3%81%8B%E3%81%8B%E3%81%97.webp`,
    desc: '',
    title: 'かかし',
    sportName: '反復横とび',
    ability: ['敏捷性', '瞬発力'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a33873',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/011_%E7%B8%84%E3%81%BE%E3%82%8F%E3%81%97.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/011.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/011_%E7%B8%84%E3%81%BE%E3%82%8F%E3%81%97.webp`,
    desc: '',
    title: '縄まわし',
    sportName: '長座体前屈',
    ability: ['柔軟性', '協調性'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a3386e',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/001_%E3%82%AD%E3%83%A3%E3%83%83%E3%83%81%E3%83%B3%E3%82%AF%E3%82%99%E3%82%B5%E3%82%99%E3%82%B9%E3%83%86%E3%82%A3%E3%83%83%E3%82%AF.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/001.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/001_%E3%82%AD%E3%83%A3%E3%83%83%E3%83%81%E3%83%B3%E3%82%AF%E3%82%99%E3%82%B5%E3%82%99%E3%82%B9%E3%83%86%E3%82%A3%E3%83%83%E3%82%AF.webp`,
    desc: '',
    title: 'キャッチングザスティック',
    sportName: '握力',
    ability: ['握力', '敏捷性', '協調性'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a33871',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/031_%E3%81%8A%E5%B0%BB%E7%9B%B8%E6%92%B2.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/031.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/031_%E3%81%8A%E5%B0%BB%E7%9B%B8%E6%92%B2.webp`,
    desc: '',
    title: 'お尻相撲',
    sportName: '上体起こし',
    ability: ['バランス', '筋力'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a33878',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/006_%E6%96%B0%E8%81%9E%E7%B4%99%E3%83%AA%E3%83%AC%E3%83%BC.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/006.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/006_%E6%96%B0%E8%81%9E%E7%B4%99%E3%83%AA%E3%83%AC%E3%83%BC.webp`,
    desc: '',
    title: '新聞紙リレー',
    sportName: '50m走',
    ability: ['敏捷性', 'スピード'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a3387a',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/007_%E3%81%97%E3%82%99%E3%82%83%E3%82%93%E3%81%91%E3%82%93%E3%82%B3%E3%83%BC%E3%83%B3.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/007.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/007_%E3%81%97%E3%82%99%E3%82%83%E3%82%93%E3%81%91%E3%82%93%E3%82%B3%E3%83%BC%E3%83%B3.webp`,
    desc: '',
    title: 'じゃんけんコーン',
    sportName: '立ち幅とび',
    ability: ['瞬発力', '敏捷性', '判断力'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a33872',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/003_%E8%82%A1%E5%89%B2%E3%82%8A%E3%82%B7%E3%82%99%E3%83%A3%E3%83%B3%E3%82%B1%E3%83%B3.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/003.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/003_%E8%82%A1%E5%89%B2%E3%82%8A%E3%82%B7%E3%82%99%E3%83%A3%E3%83%B3%E3%82%B1%E3%83%B3.webp`,
    desc: '',
    title: '股割りジャンケン',
    sportName: '長座体前屈',
    ability: ['柔軟性'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a3387b',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/015_%E5%89%8D%E5%BE%8C%E5%B7%A6%E5%8F%B3.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/015.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/015_%E5%89%8D%E5%BE%8C%E5%B7%A6%E5%8F%B3.webp`,
    desc: '',
    title: '前後左右',
    sportName: '立ち幅とび',
    ability: ['瞬発力', '思考力'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a3387d',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/082_%E3%83%86%E3%83%B3%E3%82%AB.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/082.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/082_%E3%83%86%E3%83%B3%E3%82%AB.webp`,
    desc: '',
    title: 'テンカ',
    sportName: 'ボール投げ',
    ability: ['投力', '判断力'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a33875',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/012_%E3%82%B9%E3%83%A9%E3%83%AD%E3%83%BC%E3%83%A0%E3%83%AA%E3%83%AC%E3%83%BC.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/012.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/012_%E3%82%B9%E3%83%A9%E3%83%AD%E3%83%BC%E3%83%A0%E3%83%AA%E3%83%AC%E3%83%BC.webp`,
    desc: '',
    title: 'スラロームリレー',
    sportName: '反復横とび',
    ability: ['敏捷性', 'スピード'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a33876',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/005_%E4%BD%93%E3%81%A6%E3%82%99%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/005.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/005_%E4%BD%93%E3%81%A6%E3%82%99%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88.webp`,
    desc: '',
    title: '体でアンケート',
    sportName: '20mシャトルラン',
    ability: ['全身持久力', '判断力'],
    schoolCategoryCode: ['B1'],
  },
  {
    _id: '6416d1f801cb57ff84a33877',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/013_%E5%86%86%E3%81%8A%E3%81%AB.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/013.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/013_%E5%86%86%E3%81%8A%E3%81%AB.webp`,
    desc: '',
    title: '円おに',
    sportName: '20mシャトルラン',
    ability: ['全身持久力'],
    schoolCategoryCode: ['C1', 'D1'],
  },
  {
    _id: '6416d1f801cb57ff84a33879',
    url: `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/014_%E3%83%92%E3%82%99%E3%83%95%E3%82%99%E3%82%B9%E3%83%92%E3%82%99%E3%83%B3%E3%82%B3%E3%82%99.mp4`,
    lowUrl:
      `${STORAGE_URL}/example_videos_low_quality/014.mp4`,
    thumb:
      `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/014_%E3%83%92%E3%82%99%E3%83%95%E3%82%99%E3%82%B9%E3%83%92%E3%82%99%E3%83%B3%E3%82%B3%E3%82%99.webp`,
    desc: '',
    title: 'ビブスビンゴ',
    sportName: '50m走',
    ability: ['スピード', '敏捷性', '判断力'],
    schoolCategoryCode: ['C1', 'D1'],
  },
]

const exerciseProgramVideosBaseUrl =
  `${STORAGE_URL}/%E3%80%90%E5%AE%8C%E6%88%90%E5%93%81%E3%80%91%E5%8B%95%E7%94%BB%E2%80%BB%E3%83%80%E3%83%9F%E3%83%BC%E3%81%82%E3%82%8A/`

export const exerciseProgramVideosBaseUrlLength =
  exerciseProgramVideosBaseUrl.length
