import axios from 'axios';
import * as XLSX from 'xlsx'

export const excelToJson = async (fileUrl: string) => {
  const axiosResponse = await axios({ 
    url: fileUrl,
    responseType: "arraybuffer"
  });
  const fileExcel = XLSX.read(axiosResponse.data, { type: 'buffer' })
  const sheetList = fileExcel.SheetNames
  const jsonExcel = XLSX.utils.sheet_to_json(fileExcel.Sheets[sheetList[0]])
  return jsonExcel
}
