import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Row,
  Typography,
} from 'antd'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UpdateSchoolInput, useUpdateSchoolMutation } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { useEmailPhoneStore } from '~/context/user'
import { useQuery } from '~/hooks/hooks'
import { checkIfValidPhone } from '~/school-utils/validations'
import Api from '~/utils/api'
import { STORAGE_URL } from '~/utils/constants'
import { countConfDefaultValues } from '~/utils/test-result'
import QuestionTooltip from '../cards/QuestionTooltip'
import SchoolHeader from '../cards/SchoolHeader'
import CompletionLayout from '../layout/CompletionLayout'
import { Dashboard } from '../layout/Dashboard'
import SimpleVideoModal from '../modal/SimpleVideoModal'
import ScreenLoading from '../shared/loading/ScreenLoading'

interface ISettingForm {
  telephone: string
  email: string

  /**
   * 0: false - not active
   * 1: true - active
   */
  isStudentInputActive: '0' | '1'

  /**
   * Test result input count condition
   * 0: base - 8 only
   * 1: all - all test res included height and weight
   */
  inputtedCountCondition: '0' | '1'

  /**
   * 0 - quality is low
   * 1 - quality is high
   */
  videoQualitySetting: '0' | '1'
}

interface IAdviseMovie {
  url: string
  thumb: string
  title?: string
  desc?: string
}

const { Text } = Typography

const lowHighQualityVideos: IAdviseMovie[] = [
  {
    url: `${STORAGE_URL}quality-video/low.mp4`,
    thumb: '/images/importance_mess_thumb.png',
  },
  {
    url: `${STORAGE_URL}quality-video/high.mp4`,
    thumb: '/images/importance_mess_thumb.png',
  },
] as const

type TTestCountConf = null | number[]

const convertTestInputCountConfToOption = (
  testCountConf: TTestCountConf | undefined,
): '0' | '1' =>
  testCountConf != null &&
  !testCountConf.includes(1) &&
  !testCountConf.includes(2)
    ? '0'
    : '1'

/**
 * Path: /setting
 */
const Setting = () => {
  const { t } = useTranslation()

  const { school, teacher, setTeacher, setShowUnloadPopup, nextUrl } =
    useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const studentTxt = isElementarySchool ? '児童' : '生徒'

  const { setPhone, setEmail } = useEmailPhoneStore()

  const prefectureCode = school?.attributes?.prefectureCode

  const [showSavedNotification, setShowSavedNotification] = useState(false)

  const hasFormChanged = () => {
    if (!school) return false
    const values = form.getFieldsValue()

    const isStudentInputActive = values.isStudentInputActive === '1'
    // true means quality is low ('0' - low quality)
    const videoQualitySetting = values.videoQualitySetting === '0'

    return (
      values.isStudentInputActive &&
      (values.telephone !== school.attributes.telephone ||
        values.email !== school.attributes.email ||
        isStudentInputActive !== school.attributes.isStudentInputActive ||
        values.inputtedCountCondition !==
          convertTestInputCountConfToOption(testCountConf) ||
        videoQualitySetting !== school.attributes.videoQualitySetting)
    )
  }

  const hasFormRefChanged = () => {
    const school = schoolRef.current
    if (!school) return false

    const values = formRef.current!.getFieldsValue()

    const isStudentInputActive = values.isStudentInputActive === '1'
    // true means quality is low ('0' - low quality)
    const videoQualitySetting = values.videoQualitySetting === '0'

    return (
      values.isStudentInputActive &&
      (values.telephone !== school.attributes.telephone ||
        values.email !== school.attributes.email ||
        isStudentInputActive !== school.attributes.isStudentInputActive ||
        values.inputtedCountCondition !==
          convertTestInputCountConfToOption(testCountConf) ||
        videoQualitySetting !== school.attributes.videoQualitySetting)
    )
  }

  const {
    loading: countConfLoading,
    data: testCountConf,
    invalidateCache,
  } = useQuery<number[] | null>({
    queryFn: async () => {
      return (await Api.get<TTestCountConf>('/alpha/v1/school/test-count-conf'))
        .data
    },
    cacheKey: 'test-count-conf',
  })

  const [updateSchoolMutation, { loading: updateSchoolLoading }] =
    useUpdateSchoolMutation()
  const loading = countConfLoading || updateSchoolLoading

  const [form] = Form.useForm<ISettingForm>()

  const history = useHistory()
  const [showSavingPopup, setShowSavingPopup] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<IAdviseMovie | undefined>()

  const formRef = useRef<FormInstance<ISettingForm>>()
  const schoolRef = useRef<typeof school>()

  const putCountConf = async (countConf: '0' | '1') => {
    // const countConfFor8BaseOnly = [3, 4, 5, 6, 7, 8, 9, 10]
    /**
     * 0: 8基本 - exclude weight (1) and height (2)
     * 1: 全部
     */
    const requested =
      countConf === '1' ? countConfDefaultValues : [3, 4, 5, 6, 7, 8, 9, 10]

    try {
      await Api.put('/alpha/v1/school/test-count-conf', {
        testCount: requested,
      })

      // setTestCountConf(requested)
    } catch (err) {
      console.error('test-count-conf - error: ', err)
    }
  }

  useEffect(() => {
    if (!school) {
      return
    }

    /**
     * To check if there is any change in the form
     * and if there is any change then show the popup
     * when the user tries to leave the page
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
     */
    const unloadCallback = (event: {
      preventDefault: () => void
      returnValue: string
    }) => {
      if (hasFormChanged()) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', unloadCallback)
    formRef.current = form
    schoolRef.current = school
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [form, school, testCountConf])

  // Unmount event
  useEffect(() => {
    setShowUnloadPopup(() => {
      const hasChanged = hasFormRefChanged()

      if (hasChanged) {
        setShowSavingPopup(true)
      }
      return hasChanged
    })
  }, [setShowUnloadPopup, testCountConf])

  // Initialize form with teacher data, update form values
  useEffect(() => {
    if (!teacher?.school) return

    const schoolAttributes = teacher.school.attributes

    form.setFieldsValue({
      email: schoolAttributes.email,
      telephone: schoolAttributes.telephone,

      // 1: true - active
      isStudentInputActive: schoolAttributes.isStudentInputActive ? '1' : '0',
      inputtedCountCondition: convertTestInputCountConfToOption(testCountConf),

      // true means quality is low ('0' - low quality)
      videoQualitySetting: schoolAttributes.videoQualitySetting ? '0' : '1',
    })
  }, [teacher, form, testCountConf])

  const onFinish = async (values: {
    email: string
    telephone: string
    isStudentInputActive: '1' | '0'
    videoQualitySetting: '1' | '0'
    inputtedCountCondition: '0' | '1'
  }) => {
    if (!school || prefectureCode == null) {
      toast.error(`${t('エラーが発生しました。')} School is not available!`)
      return
    }

    if (!teacher) {
      toast.error(`${t('エラーが発生しました。')} Teacher is not available!`)
      return
    }

    // Check if form values have actually changed
    if (!hasFormChanged()) {
      setShowSavedNotification(true)
      setTimeout(() => {
        setShowSavedNotification(false)
        history.push('/home')
      }, 2000)
      return
    }

    const isStudentInputActive = values.isStudentInputActive === '1'
    const videoQualitySetting = values.videoQualitySetting === '0'

    // Update school settings
    const variables: { input: UpdateSchoolInput } = {
      input: {
        schoolId: school._id,
        attributes: {
          telephone: values.telephone,
          email: values.email,
          isStudentInputActive,
          videoQualitySetting,
        },
      },
    }

    try {
      /**
       * Ignore response from `putCountConf`.
       */
      const currentInputtedCountCondition =
        convertTestInputCountConfToOption(testCountConf)
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const promises: any[] = [updateSchoolMutation({ variables })]

      // Only update test count condition if it has changed
      if (values.inputtedCountCondition !== currentInputtedCountCondition) {
        promises.push(putCountConf(values.inputtedCountCondition))
        invalidateCache()
      }

      const [res] = await Promise.all(promises)

      if (res.errors) {
        toast.error(
          `${t('エラーが発生しました。')} [${res.errors[0]?.message}]`,
        )
        console.error('res.errors:', res.errors)
        return
      }

      const updateSchool = res.data?.updateSchool
      if (!updateSchool?.success) {
        toast.error(`${t('エラーが発生しました。')} [${updateSchool?.error}]`)
        console.error('updateSchool?.error:', updateSchool?.error)
        return
      }

      // successfully updated
      setTeacher({
        ...teacher,
        school: {
          ...teacher.school,
          attributes: {
            ...teacher.school.attributes,
            telephone: values.telephone,
            email: values.email,
            isStudentInputActive,
            videoQualitySetting,
          },
        },
      })

      setPhone(values.telephone)
      setEmail(values.email)

      setShowSavedNotification(true)
      setTimeout(() => {
        setShowSavedNotification(false)
        history.push('/home')
      }, 2000)
    } catch (err) {
      toast.error(`${t('エラーが発生しました。')} [${JSON.stringify(err)}]`)
      console.error('when updateSchoolMutation, error:', err)
    }
  }

  return (
    <>
      {school && teacher ? (
        <Dashboard selectedMenu={9} navbar={t('設定')}>
          {showSavedNotification ? (
            <CompletionLayout message={t('登録完了')} />
          ) : (
            <>
              <SchoolHeader school={school} teacher={teacher} />

              <div className="flex justify-center pt-2 pb-4">
                <Form
                  form={form}
                  layout="vertical"
                  className="max-w-400"
                  initialValues={{
                    email: school.attributes.email,
                    telephone: school.attributes.telephone,
                    isStudentInputActive: school.attributes.isStudentInputActive
                      ? '1'
                      : '0',

                    inputtedCountCondition: '1',
                    videoQualitySetting: school.attributes.videoQualitySetting
                      ? '0'
                      : '1',
                  }}
                  onFinish={onFinish}
                  requiredMark={false}
                >
                  <Row gutter={10}>
                    <Col span={24}>
                      <Form.Item
                        name="telephone"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('学校電話番号')}
                            </Text>
                            <Button
                              type="primary"
                              className="ant-btn-ti ml-2"
                              danger
                            >
                              <div className="h-full flex justify-items-center items-center">
                                {t('必須')}
                              </div>
                            </Button>
                          </>
                        }
                        rules={[
                          {
                            required: true,
                            message: t('学校電話番号を入力して下さい。'),
                          },
                          () => ({
                            validator(_, value) {
                              if (!value || checkIfValidPhone(value)) {
                                return Promise.resolve()
                              }

                              return Promise.reject(
                                new Error(
                                  t(
                                    '電話番号を半角数字で正しく入力してください。',
                                  ),
                                ),
                              )
                            },
                          }),
                        ]}
                      >
                        <Input className="bg-gray-150" disabled={loading} />
                      </Form.Item>

                      <div className="flex flex-col" style={{ width: '120%' }}>
                        <div className="flex">
                          <Text className="text-xs font-bold">
                            {t('学校代表者メールアドレス')}
                          </Text>
                          <Button
                            type="primary"
                            className="ant-btn-ti ml-2"
                            danger
                          >
                            <div className="h-full flex justify-items-center items-center">
                              {t('必須')}
                            </div>
                          </Button>
                        </div>
                        {/* <Text className="pt-1 ant-table-column-sorter-down">
                          ALPHA担当者より重要なお知らせをお送りすることがあります。
                          <br />
                          ページ下部の「登録」ボタン押下後、登録完了メールが届いていることを確認してください。
                          <br />
                          ※メールアドレスの登録・変更を行った場合のみ送信されます。
                        </Text> */}
                      </div>

                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t(
                              '学校代表者メールアドレスを入力して下さい。',
                            ),
                          },
                          {
                            type: 'email',
                            message: t(
                              '学校代表者メールアドレスを正しく入力してください。',
                            ),
                          },
                        ]}
                      >
                        <Input className="bg-gray-150" disabled={loading} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="isStudentInputActive"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('{生徒}ALPHAでの測定結果の入力設定', {
                                student: studentTxt,
                              })}
                            </Text>

                            <QuestionTooltip
                              TitleChild={
                                <span className="p-2">
                                  {t(
                                    '入力可：{生徒}ALPHAで記録の入力・編集・削除を行うことができます。',
                                    { student: studentTxt },
                                  )}
                                  <br />
                                  {t(
                                    '入力不可：{生徒}ALPHAで記録の入力・編集・削除を行うことができません。',
                                    { student: studentTxt },
                                  )}
                                  <br />※
                                  {t(
                                    '学校の記録を確定させたい場合などに「入力不可」にご変更ください。',
                                  )}
                                  <br />
                                </span>
                              }
                            />
                          </>
                        }
                      >
                        <Radio.Group>
                          <Radio key="input-on" value="1" checked={true}>
                            {t('入力可')}
                          </Radio>
                          <Radio key="input-off" value="0">
                            {t('入力不可')}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="inputtedCountCondition"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('{生徒}の入力済み判定条件の変更', {
                                student: studentTxt,
                              })}
                            </Text>

                            <QuestionTooltip
                              TitleChild={
                                <span className="p-2">
                                  {t(
                                    '設定することで、{生徒}の入力進捗が正しく確認できます。',
                                    { student: studentTxt },
                                  )}
                                  <br />
                                  {t(
                                    '選択した種目がすべて入力された{生徒}は以下の2つのページで',
                                    { student: studentTxt },
                                  )}
                                  <br />
                                  {t('「入力済み」とカウントされます。')}
                                  <br />
                                  <br />
                                  {t('対象ページ')}
                                  <br />　{t('【ホーム】体力テスト入力済人数')}
                                  <br />　{t('【入力】→【体力テスト】登録状況')}
                                  <br />
                                  <br />※
                                  {t(
                                    '持久走/シャトルランは、どちらか一つ登録されていれば入力と判定。',
                                  )}
                                  <br />※
                                  {t(
                                    '持久走/シャトルランは小学校の場合、シャトルランのみ。',
                                  )}
                                </span>
                              }
                            />
                          </>
                        }
                      >
                        <Radio.Group>
                          <Radio key="all" value="1" checked={true}>
                            {t('体力テスト（8種目）、身長、体重')}
                          </Radio>
                          <Radio key="base" value="0">
                            {t('体力テスト（8種目）のみ')}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name="videoQualitySetting"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('動画の画質設定')}
                            </Text>

                            <QuestionTooltip
                              TitleChild={
                                <span className="p-2">
                                  {t('動画の画質を選択することができます。')}
                                  <br />
                                  {t(
                                    'WiFiが弱い環境では低画質にすることで動画の読み込み時間が短縮されます。',
                                  )}
                                  <br />
                                  {t(
                                    '設定した画質が教職員・児童生徒のデフォルト画質になります。',
                                  )}
                                  <br />
                                </span>
                              }
                            />
                          </>
                        }
                      >
                        <Radio.Group className="w-full">
                          {/* show video demo for quality setting */}
                          <div className="relative h-48">
                            <div className="flex absolute -left-28">
                              <div className="flex flex-col">
                                <Radio key="input-on" value="1" checked={true}>
                                  {t('高画質')}
                                </Radio>
                                <div className="h-2" />

                                <div
                                  className="cursor-pointer w-72"
                                  onClick={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[1])
                                    event.stopPropagation()
                                  }}
                                  onTouchEnd={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[1])
                                    event.stopPropagation()
                                  }}
                                >
                                  <img
                                    src={lowHighQualityVideos[1].thumb}
                                    alt=""
                                  />
                                </div>
                              </div>

                              <div className="w-10" />

                              <div className="flex flex-col">
                                <Radio key="input-off" value="0">
                                  {t('低画質')}
                                </Radio>
                                <div className="h-2" />

                                <div
                                  className="cursor-pointer w-72"
                                  onClick={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[0])
                                    event.stopPropagation()
                                  }}
                                  onTouchEnd={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[0])
                                    event.stopPropagation()
                                  }}
                                >
                                  <img
                                    src={lowHighQualityVideos[0].thumb}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Radio.Group>
                      </Form.Item>

                      <div className="text-center">
                        <Form.Item>
                          <Button
                            className="w-60 mt-6 rounded"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            {t('登録')}
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </>
          )}
        </Dashboard>
      ) : (
        <Dashboard selectedMenu={8} navbar={t('設定')}>
          <div />
        </Dashboard>
      )}

      <ScreenLoading isLoading={loading} />

      {selectedVideo && (
        <SimpleVideoModal
          video={selectedVideo}
          onClose={() => setSelectedVideo(undefined)}
        />
      )}

      <Modal
        visible={showSavingPopup}
        footer={null}
        closable={false}
        className="mt-4"
      >
        <div
          className="justify-center flex"
          style={{
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '21px',
            margin: '-24px -24px 0',
            padding: '24px 0',
          }}
        >
          <span>
            {t('このページから移動しますか？')}
            <br />
            {t('変更内容を保存する場合は、必ず「登録」ボタンを押してください')}
            <br />
          </span>
        </div>
        <div className="mt-8 justify-center flex">
          <Button
            className="w-60 mr-2"
            size="large"
            onClick={() => {
              setShowSavingPopup(() => false)
              const goToUrl = nextUrl ?? ''
              if (goToUrl.startsWith('http')) {
                window.open(goToUrl, '_blank')
              } else {
                history.push(goToUrl)
              }
            }}
          >
            {t('保存せずに移動')}
          </Button>
          <Button
            type="primary"
            className="w-60 ml-2"
            size="large"
            onClick={() => {
              setShowSavingPopup(false)
            }}
          >
            {t('設定に戻る')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default Setting
