import './test-input-page.css'

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { TestResult, useStudentsLazyQuery } from '@alpha/core'
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { ColumnsType, SortOrder } from 'antd/lib/table/interface'
import { useAlphaStore } from '~/context'
import { useIsOnlineStore } from '~/context/online'
import { useQuery } from '~/hooks/hooks'
import { useLoadingPercent } from '~/hooks/useLoadingPercent'
import {
  ageFromSchool,
  checkIfIsValidRank,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '~/utils'
import Api from '~/utils/api'
import { calculateBmiByExistWeightHeight } from '~/utils/bmi'
import {
  currentYear,
  pageSize as defaultPageSize,
  globalInputKeys,
} from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import {
  TAllTestKeyHeightWeight,
  awardTestKeysHasShuttleRunOnly,
} from '~/utils/test-const'
import {
  checkIfIsAllTestResInputted,
  checkIfIsAllTestResInputtedForStatistics,
} from '~/utils/test-result'
import { IStudent, TStudent } from '../../utils/types/api/res'
import { Dashboard } from '../layout/Dashboard'
import CircularProgressLoading from '../shared/CircularProgressLoading'
import { incorrectValue } from '../shared/IncorrectValue'
import MeasurableModal from './test-input/components/MeasurableModal'
import { TestDetailsModal } from './test-input/components/TestDetailsModal'
import TestInputField from './test-input/components/TestInputField'
import TestResInput from './test-input/components/TestResInput'
import {
  InputConfig,
  getInputListOfTestRes,
  getNoEnduranceRunInputList,
  listInputEdit,
} from './test-input/constants'
import {
  checkChangeTestKey,
  getTestNameByTestKey,
} from './test-input/utils/change'
import { convertValueForReqApi, setFormAll } from './test-input/utils/form'
import { sortTestResults } from './test-input/utils/get'

const { Option } = Select

/**
 * 体力テスト結果の入力ページ
 *
 * Path: /test
 */
const TestInputPage = () => {
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  /**
   * Using at table.
   */
  const [filteredStudents, setFilteredStudents] = useState<IStudent[]>([])
  const studentsRef = useRef<IStudent[]>([])
  const [isNotMeasurable, setIsNotMeasurable] = useState<
    Record<string, boolean>
  >({})
  const [filterGender, setFilterGender] = useState('')
  const [selectedStudent, setSelectedStudent] = useState<null | IStudent>(null)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [isDetailLoading, setIsDetailLoading] = useState(false)
  const [idModal, setIdModal] = useState('')
  const [isReadyToCallGetStudents, setIsReadyToCallGetStudents] =
    useState(false)

  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const [isHighlightInputtedVal, setIsHighlightInputtedVal] = useState(false)

  const [measurableModalObj, setMeasurableModalObj] = useState<
    boolean | { studentId: string; keyId: number }
  >(false)

  const [form] = Form.useForm()
  const [formEdit] = Form.useForm()
  const submittedRef = useRef<HTMLDivElement>(null)
  const submittingRef = useRef<HTMLDivElement>(null)

  const [getStudents, { data: studentData, loading: loadingStudent }] =
    useStudentsLazyQuery({
      fetchPolicy: 'network-only',
    })

  const { loading: countLoading, data: countConf } = useQuery<number[] | null>({
    queryFn: async () => {
      return (
        await Api.get<number[] | null>('/alpha/v1/school/test-count-conf')
      ).data
    },
    cacheKey: 'test-count-conf',
  })

  const loadingPercent = useLoadingPercent(160)

  const { isOnline } = useIsOnlineStore()

  const { data: filterData } = useQuery({
    queryFn: async () => {
      const { data: dataFilter } = await Api.get<{
        grades: number[]
        classes: number[]
      }>('/alpha/v1/school/filterStudents')

      const grades = dataFilter.grades
      const classes = dataFilter.classes

      setSchoolGrade(grades.length ? grades[0] : 0)
      setSchoolClass(classes.length ? classes[0] : 0)

      setIsReadyToCallGetStudents(true)
      return dataFilter
    },
  })

  // loadingStudents
  useEffect(() => {
    if (!school || !isReadyToCallGetStudents) return

    getStudents({
      variables: {
        input: {
          schoolId: school._id,
          testYear: currentYear,
          schoolClass,
          schoolGrade,
        },
      },
    })
  }, [school, getStudents, schoolGrade, schoolClass, isReadyToCallGetStudents])

  // setFilteredStudents
  useEffect(() => {
    if (!school) return

    let _filteredStudents: IStudent[]

    if (isOnline) {
      if (!studentData?.students?.data) {
        return
      }

      setTotal(studentData.students.count ?? 0)
      _filteredStudents = studentData.students.data as IStudent[]
    } else {
      if (!studentData?.students?.data) {
        studentsRef.current = []
        setFilteredStudents([])
        setTotal(0)
        return
      }

      _filteredStudents = studentData.students.data.filter(
        (s) =>
          s.attributes.schoolClass === schoolClass &&
          s.attributes.schoolGrade === schoolGrade,
      ) as IStudent[]
    }

    if (filterGender !== '') {
      _filteredStudents = _filteredStudents.filter(
        (student) => student.attributes.gender === filterGender,
      )
    }

    const schoolCategoryCode = school.attributes.schoolCategoryCode

    _filteredStudents.forEach((student) => {
      if (!student?.attributes?.testResults) return

      const testResults = student.attributes.testResults

      const trIdx = testResults.findIndex((d) => d.testYear === currentYear)

      if (trIdx >= 0) {
        const age = ageFromSchool(
          schoolCategoryCode,
          student.attributes.schoolGrade as number,
        )

        const gender = student.attributes.gender

        if (gender) {
          const testRes = testResults[trIdx]

          // @ts-ignore
          student.attributes.testResults[trIdx] = reCalcTestResult(
            testRes,
            age,
            gender,
            hasEnduranceRun,
          )
        }
      }
    })

    setFilteredStudents(_filteredStudents)
    studentsRef.current = _filteredStudents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    school,
    studentData?.students?.data,
    studentData?.students?.count,
    filterGender,
    isOnline,
    schoolClass,
    schoolGrade,
  ])

  const onChangeGrade = useCallback((val: number) => {
    setSchoolGrade(val)
  }, [])

  const onChangeClass = useCallback((val: number) => {
    setSchoolClass(val)
  }, [])

  const onChangeGender = useCallback((val: string) => {
    setFilterGender(val)
  }, [])

  const hasEnduranceRun = schoolHasEnduranceTest(school?.attributes)

  const inputKeys = hasEnduranceRun
    ? globalInputKeys
    : awardTestKeysHasShuttleRunOnly

  const getInputList = () => {
    return hasEnduranceRun
      ? getInputListOfTestRes(t)
      : getNoEnduranceRunInputList(t)
  }

  const renderTest = (v, test) => {
    const defVal = incorrectValue
    if (!v || v.length === 0) return defVal
    const tr = v.find((d) => d.testYear === currentYear)
    if (!tr || tr.length === 0) return defVal

    switch (test) {
      case 'bending':
      case 'standingJump':
      case 'handballThrow': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (value === null || (!value && test !== 'sitUps')) return defVal
        return <div>{value}</div>
      }
      case 'grip':
      case 'sitUps':
      case 'sprintRun':
      case 'sideJump':
      case 'shuttleRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (value === null || (!value && test !== 'sitUps')) return defVal
        return <div>{value}</div>
      }
      // this is wrong (originally at least). runningTime is not a number. is the timeLabel (mm:ss)
      // runningTimeSeconds is the sum of minutes and seconds.
      case 'enduranceRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        if (tr.enduranceRun?.isNotMeasurable === true) return '無'
        const value =
          tr[test].runningTime && tr[test].runningTimeSeconds
            ? `${tr.enduranceRun?.runningTime}'${tr.enduranceRun?.runningTimeSeconds}''`
            : defVal
        if (!value) return defVal
        return <div>{value}</div>
      }
      case 'height':
      case 'weight': {
        const value = tr.sizeTest?.[test]
        if (!value || value.length === 0) return defVal
        return <div>{value}</div>
      }
      case 'bmi': {
        let value = ''
        if (tr?.sizeTest?.weight && tr.sizeTest.height) {
          value = calculateBmiByExistWeightHeight(tr.sizeTest)

          if (!value || value.length === 0) return defVal
        } else {
          return defVal
        }
        return <div>{Number(value)}</div>
      }
    }
  }

  const measurableCheckboxWarning = (
    <>
      {t('「今年度測定なし」にチェックが入っているため編集ができません。')}
      <br />
      {t(
        '記録を入力する場合は詳細ボタンから「今年度測定なし」のチェックを外してください。',
      )}
    </>
  )

  const renderInput = (input: InputConfig) => {
    const tabTitleDefaultWidth = isUsingJp ? 'w-16' : 'w-32'
    const isDisabled = input.measurable && isNotMeasurable[input.key]

    return (
      <div key={input.key}>
        <Row>
          <div
            className={`h-5 ${
              input.tab_width ? input.tab_width : tabTitleDefaultWidth
            } bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black`}
          >
            {input.tab}
          </div>
        </Row>
        <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
          {input.items.map((item, index) => {
            return (
              <Col key={index} span={6}>
                <div className="flex relative">
                  {isDisabled ? (
                    <Popover content={measurableCheckboxWarning}>
                      <TestResInput
                        item={item}
                        input={input}
                        isNotMeasurable={isNotMeasurable}
                      />
                    </Popover>
                  ) : (
                    <TestResInput
                      item={item}
                      input={input}
                      isNotMeasurable={isNotMeasurable}
                    />
                  )}
                </div>
              </Col>
            )
          })}

          {input.measurable && (
            <Col key="measurable" span={input.items.length >= 4 ? 24 : 6}>
              <Form.Item
                className="mb-0-f"
                style={
                  input.items.find((item) => !item.label) ||
                  input.items.length === 4
                    ? {}
                    : { position: 'relative', top: '50%' }
                }
                name={`${input.key}NotMeasurable`}
                valuePropName="checked"
              >
                <Checkbox
                  className="text-10px font-normal"
                  onChange={(event) => {
                    const newValue = {
                      ...isNotMeasurable,
                    }
                    newValue[input.key] = event.target.checked
                    setIsNotMeasurable(newValue)
                    form.validateFields()
                  }}
                >
                  {t('今年度測定なし')}
                </Checkbox>
              </Form.Item>
            </Col>
          )}

          <Col key="memo" span={24}>
            <label className="text-10px">{t('メモ')}</label>
            <Form.Item className="mb-0-f" name={`${input.key}Memo`}>
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    )
  }

  const onFinishRegisterByDetailModal = useCallback(
    async (values) => {
      if (!school) {
        toast.error(`${t('エラーが発生しました。')} [school is not found]`)
        console.error('when call school/result, error: school is not found')
        return
      }

      // selectedStudent is must be IStudent, because this function is called
      // when selectedStudent is not null.
      const updatedTestResOfStudent = convertValueForReqApi(
        values,
        isNotMeasurable,
        idModal,
        studentsRef.current as TStudent[],
        selectedStudent as IStudent,
      )

      setReloading(true)
      setIsDetailLoading(true)

      await Api.post('/alpha/v1/school/result', updatedTestResOfStudent)
      form.resetFields()

      await getStudents({
        variables: {
          input: {
            schoolId: school._id,
            testYear: currentYear,
            schoolClass,
            schoolGrade,
          },
        },
      })

      // Do not use antd's message to showing success message,
      // because antd's message can make error:
      // Failed to execute 'removeChild' on 'Node':
      // The node to be removed is not a child of this node.
      toast.success(t('登録完了'))
      setShowDetailModal(false)

      setReloading(false)
      setIsDetailLoading(false)
      setIdModal('')
    },
    [
      school,
      t,
      isNotMeasurable,
      idModal,
      selectedStudent,
      form,
      getStudents,
      schoolClass,
      schoolGrade,
    ],
  )

  const totalCompletion = (filteredStudents ?? []).filter((result) =>
    checkIfIsAllTestResInputted(
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      result?.attributes?.testResults as Record<string, any>[] | undefined,
      hasEnduranceRun,
      inputKeys,
      currentYear,
    ),
  ).length

  const tableColumns = useMemo(() => {
    const saveStudent = async (id: string, testKey: string) => {
      if (!school) {
        toast.error(
          `${t('エラーが発生しました。')} [school is not found when save student]`,
        )
        return
      }

      let dataSaveEdit = {}
      let checkValidate = false
      const dataEdit: (IStudent & { index: number })[] = []
      let testIndex = 0
      for (let i = 0; i < studentsRef.current.length; i++) {
        if (studentsRef.current[i]?._id === id) {
          testIndex = i
          dataEdit.push({ ...studentsRef.current[i], index: i })
        }
      }
      let canBeEdited = false
      // setReloading(true)
      dataEdit.forEach((test) => {
        const _id = test._id

        listInputEdit.forEach((input) => {
          if (input === testKey) {
            const listValidate = formEdit.getFieldError([`${input}_${_id}`])
            if (listValidate.length) {
              checkValidate = true
            }
          }
        })

        if (checkValidate) {
          return
        }

        const dataFormEdit = formEdit.getFieldsValue()
        const dataSave = {
          [testKey]: dataFormEdit[`${testKey}_${_id}`],
        }

        // check no change
        const oldData =
          studentsRef.current?.[test?.index]?.attributes?.testResults?.[0]
        if (oldData && !checkChangeTestKey(dataSave, oldData, testKey)) {
          return
        }

        const testKeyName = `${getTestNameByTestKey(testKey)}Test`
        dataSaveEdit = {
          ...(testKey !== 'height' && testKey !== 'weight'
            ? {
                [testKeyName]: {
                  [getTestNameByTestKey(testKey)]: {
                    [testKey]: dataFormEdit[`${testKey}_${_id}`],
                  },
                },
              }
            : {
                sizeTest: {
                  [testKey]: dataFormEdit[`${testKey}_${_id}`],
                },
              }),
          studentId: _id,
        }

        canBeEdited = true
      })

      if (checkValidate || !canBeEdited) {
        return
      }

      if (Object.keys(dataSaveEdit).length > 0) {
        setShowDetailModal(false)
        submittedRef.current?.setAttribute('class', 'flex hidden')
        submittingRef.current?.setAttribute('class', 'flex')
        const {
          data: { data: newData },
        } = await Api.post('/alpha/v1/school/saveStudentTest', dataSaveEdit)
        // Recalculate test result
        const schoolCategoryCode = school.attributes.schoolCategoryCode
        const age = ageFromSchool(
          schoolCategoryCode,
          studentsRef.current?.[testIndex].attributes.schoolGrade as number,
        )

        const gender = studentsRef.current?.[testIndex].attributes.gender
        const testResults =
          studentsRef.current[testIndex].attributes.testResults
        if (gender && testResults && testResults.length > 0) {
          testResults[0] = reCalcTestResult(
            newData,
            age,
            gender,
            hasEnduranceRun,
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          ) as any

          if (document.getElementById(`${newData.studentId}_rank`)) {
            document.getElementById(`${newData.studentId}_rank`)!.innerHTML =
              String(testResults[0].rank ?? '')
          }
          if (document.getElementById(`${newData.studentId}_points`)) {
            document.getElementById(`${newData.studentId}_points`)!.innerHTML =
              String(testResults[0].points ?? '')
          }
          if (document.getElementById(`${newData.studentId}_bmi`)) {
            document.getElementById(`${newData.studentId}_bmi`)!.innerHTML =
              testResults[0].sizeTest?.height && testResults[0].sizeTest?.weight
                ? calculateBmiByExistWeightHeight(
                    testResults[0].sizeTest as {
                      weight: number
                      height: number
                    },
                  )
                : ''
          }
          if (document.getElementById(`${newData.studentId}_isAllInputted`)) {
            const isAllInputted = checkIfIsAllTestResInputtedForStatistics(
              testResults,
              hasEnduranceRun,
              inputKeys,
              countConf,
            )

            document
              .getElementById(`${newData.studentId}_isAllInputted`)
              ?.setAttribute(
                'style',
                `background-color: ${isAllInputted ? '#00944D' : '#CA4141'}`,
              )
            document.getElementById(
              `${newData.studentId}_isAllInputted`,
            )!.innerHTML = isAllInputted ? '済' : '未'
          }
          const totalCompletion =
            (studentsRef?.current ?? []).filter((result) =>
              checkIfIsAllTestResInputted(
                result?.attributes?.testResults as
                  | Record<string, any>[]
                  | undefined,
                hasEnduranceRun,
                inputKeys,
                currentYear,
              ),
            )?.length ?? 0
          document.getElementById('totalCompletion')!.innerHTML =
            String(totalCompletion)
          document.getElementById('totalUncompletion')!.innerHTML = String(
            total - totalCompletion,
          )
        }
        submittedRef.current?.setAttribute('class', 'flex')
        submittingRef.current?.setAttribute('class', 'flex hidden')
      }
    }

    const EditableCell = (
      studentId: string,
      dataIndex: TAllTestKeyHeightWeight,
      isDisabled: boolean,
    ) => {
      let form: null | JSX.Element = null

      switch (dataIndex) {
        case 'height':
          form = (
            <div className="flex items-center relative mr-4">
              {/* {dataFormItem(dataIndex, studentId, isDisabled, dataIndex)} */}
              <TestInputField
                dataIndex="height"
                studentId={studentId}
                isDisabled={isDisabled}
                mainTestIndex={dataIndex}
                isElementarySchool={isElementarySchool}
                isHighlightInputtedVal={isHighlightInputtedVal}
                saveStudent={saveStudent}
                setMeasurableModalObj={setMeasurableModalObj}
                formEdit={formEdit}
              />
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">cm</span>
              </div>
            </div>
          )
          break

        case 'sitUps':
          form = (
            <div className="flex items-center relative mr-4">
              {/* {dataFormItem(dataIndex, studentId, isDisabled, dataIndex)} */}
              <TestInputField
                dataIndex="sitUps"
                studentId={studentId}
                isDisabled={isDisabled}
                mainTestIndex={dataIndex}
                isElementarySchool={isElementarySchool}
                isHighlightInputtedVal={isHighlightInputtedVal}
                saveStudent={saveStudent}
                setMeasurableModalObj={setMeasurableModalObj}
                formEdit={formEdit}
              />
              <div className="flex items-center -ml-2 text-10px">{t('回')}</div>
            </div>
          )
          break
        case 'weight':
          form = (
            <div className="flex items-center relative mr-4">
              {/* {dataFormItem(dataIndex, studentId, isDisabled, dataIndex)} */}
              <TestInputField
                dataIndex="weight"
                studentId={studentId}
                isDisabled={isDisabled}
                mainTestIndex={dataIndex}
                isElementarySchool={isElementarySchool}
                isHighlightInputtedVal={isHighlightInputtedVal}
                saveStudent={saveStudent}
                setMeasurableModalObj={setMeasurableModalObj}
                formEdit={formEdit}
              />
              <div className="flex items-end absolute right-unit top-4">
                <span className="text-10px">kg</span>
              </div>
            </div>
          )
          break
        case 'grip': {
          // const gripRight1 = dataFormItem(
          //   'gripRight1',
          //   studentId,
          //   isDisabled,
          //   dataIndex,
          // )
          // const gripRight2 = dataFormItem(
          //   'gripRight2',
          //   studentId,
          //   isDisabled,
          //   dataIndex,
          // )
          // const gripLeft1 = dataFormItem(
          //   'gripLeft1',
          //   studentId,
          //   isDisabled,
          //   dataIndex,
          // )
          // const gripLeft2 = dataFormItem(
          //   'gripLeft2',
          //   studentId,
          //   isDisabled,
          //   dataIndex,
          // )

          form = isUsingJp ? (
            <>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">右 (1回目)</span>
                {/* {gripRight1} */}
                <TestInputField
                  dataIndex="gripRight1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>

              <div className="flex relative mr-4">
                <span className="mr-0 text-title">右 (2回目)</span>
                {/* {gripRight2} */}
                <TestInputField
                  dataIndex="gripRight2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>

              <div className="flex relative mr-4">
                <span className="mr-0 text-title">左 (1回目)</span>
                {/* {gripLeft1} */}
                <TestInputField
                  dataIndex="gripLeft1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>

              <div className="flex relative mr-4">
                <span className="mr-0 text-title">左 (2回目)</span>
                {/* {gripLeft2} */}
                <TestInputField
                  dataIndex="gripLeft2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex relative mr-4 text-xs">
                <span className="mr-0 text-title text-title-en">
                  {t('右')}
                  <br />
                  {t('1回目_test')}
                </span>
                {/* {gripRight1} */}
                <TestInputField
                  dataIndex="gripRight1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>

              <div className="flex relative mr-4 text-xs">
                <span className="mr-0 text-title text-title-en">
                  {t('右')}
                  <br />
                  {t('2回目_test')}
                </span>
                {/* {gripRight2} */}
                <TestInputField
                  dataIndex="gripRight2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>

              <div className="flex relative mr-4 text-xs">
                <span className="mr-0 text-title text-title-en">
                  {t('左')}
                  <br />
                  {t('1回目_test')}
                </span>
                {/* {gripLeft1} */}
                <TestInputField
                  dataIndex="gripLeft1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>

              <div className="flex relative mr-4 text-xs">
                <span className="mr-0 text-title text-title-en">
                  {t('左')}
                  <br />
                  {t('2回目_test')}
                </span>
                {/* {gripLeft2} */}
                <TestInputField
                  dataIndex="gripLeft2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">kg</span>
                </div>
              </div>
            </>
          )
          break
        }

        case 'bending':
          form = (
            <>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('1回目_test')}</span>
                {/* {dataFormItem('bending1', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="bending1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">cm</span>
                </div>
              </div>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('2回目_test')}</span>
                {/* {dataFormItem('bending2', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="bending2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">cm</span>
                </div>
              </div>
            </>
          )
          break

        case 'sideJump':
          form = (
            <>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('1回目_test')}</span>
                {/* {dataFormItem('sideJump1', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="sideJump1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-center -ml-2 text-10px">
                  {t('回')}
                </div>
              </div>

              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('2回目_test')}</span>
                {/* {dataFormItem('sideJump2', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="sideJump2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-center -ml-2 text-10px">
                  {t('回')}
                </div>
              </div>
            </>
          )
          break

        case 'standingJump':
          form = (
            <>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('1回目_test')}</span>
                {/* {dataFormItem('standingJump1', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="standingJump1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">cm</span>
                </div>
              </div>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('2回目_test')}</span>
                {/* {dataFormItem('standingJump2', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="standingJump2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">cm</span>
                </div>
              </div>
            </>
          )
          break

        case 'handballThrow':
          form = (
            <>
              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('1回目_test')}</span>
                {/* {dataFormItem('handballThrow1', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="handballThrow1"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">m</span>
                </div>
              </div>

              <div className="flex relative mr-4">
                <span className="mr-0 text-title">{t('2回目_test')}</span>
                {/* {dataFormItem('handballThrow2', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="handballThrow2"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">m</span>
                </div>
              </div>
            </>
          )
          break

        case 'enduranceRun':
          form = (
            <>
              <div className="flex items-center relative mr-4">
                {/* {dataFormItem('runningTime', studentId, isDisabled, dataIndex)} */}
                <TestInputField
                  dataIndex="runningTime"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">{t('分')}</span>
                </div>
              </div>
              <div className="flex items-center relative mr-4">
                {/* {dataFormItem(
                  'runningTimeSeconds',
                  studentId,
                  isDisabled,
                  dataIndex,
                )} */}

                <TestInputField
                  dataIndex="runningTimeSeconds"
                  studentId={studentId}
                  isDisabled={isDisabled}
                  mainTestIndex={dataIndex}
                  isElementarySchool={isElementarySchool}
                  isHighlightInputtedVal={isHighlightInputtedVal}
                  saveStudent={saveStudent}
                  setMeasurableModalObj={setMeasurableModalObj}
                  formEdit={formEdit}
                />
                <div className="flex items-end absolute right-unit top-4">
                  <span className="text-10px">{t('秒')}</span>
                </div>
              </div>
            </>
          )
          break

        case 'shuttleRun':
          form = (
            <div className="flex items-center relative mr-4">
              {/* {dataFormItem('shuttleRunCount', studentId, isDisabled, dataIndex)} */}
              <TestInputField
                dataIndex="shuttleRunCount"
                studentId={studentId}
                isDisabled={isDisabled}
                mainTestIndex={dataIndex}
                isElementarySchool={isElementarySchool}
                isHighlightInputtedVal={isHighlightInputtedVal}
                saveStudent={saveStudent}
                setMeasurableModalObj={setMeasurableModalObj}
                formEdit={formEdit}
              />
              <div className="flex items-end -ml-2 text-10px">{t('回')}</div>
            </div>
          )
          break

        case 'sprintRun':
          form = (
            <div className="flex items-center relative mr-4">
              {/* {dataFormItem('sprintRunSeconds', studentId, isDisabled, dataIndex)} */}
              <TestInputField
                dataIndex="sprintRunSeconds"
                studentId={studentId}
                isDisabled={isDisabled}
                mainTestIndex={dataIndex}
                isElementarySchool={isElementarySchool}
                isHighlightInputtedVal={isHighlightInputtedVal}
                saveStudent={saveStudent}
                setMeasurableModalObj={setMeasurableModalObj}
                formEdit={formEdit}
              />
              <div
                className={`flex items-end absolute -right-2 top-4 ${
                  isUsingJp ? 'right-unit' : '-right-2'
                }`}
              >
                <span className="text-10px">{t('秒')}</span>
              </div>
            </div>
          )
          break
      }

      return <div className="form-edit-table-test flex">{form}</div>
    }

    const tableColumnsRes = [
      {
        title: t('登録状況'),
        dataIndex: ['attributes', 'testResults'],
        key: 'status',
        fixed: 'left',
        width: 85,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          const status1 = checkIfIsAllTestResInputted(
            a?.attributes?.testResults,
            hasEnduranceRun,
            inputKeys,
            currentYear,
          )
          const status2 = checkIfIsAllTestResInputted(
            b?.attributes?.testResults,
            hasEnduranceRun,
            inputKeys,
            currentYear,
          )

          return status1 === status2 ? 0 : status1 ? 1 : -1
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          testResArr: Record<string, any>[] | undefined,
          student: IStudent,
        ) => {
          const isAllInputted = checkIfIsAllTestResInputtedForStatistics(
            testResArr,
            hasEnduranceRun,
            inputKeys,
            countConf,
          )

          return (
            <div
              className="flex justify-center items-center"
              style={{ height: 22 }}
            >
              <div
                className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
                style={{
                  backgroundColor: isAllInputted ? '#00944D' : '#CA4141',
                }}
                id={`${student._id}_isAllInputted`}
              >
                {isAllInputted ? '済' : '未'}
              </div>
            </div>
          )
        },
      },
      {
        title: t('学年'),
        dataIndex: ['attributes', 'schoolGrade'],
        key: 'schoolGrade',
        fixed: 'left',
        width: isUsingJp ? 40 : 78,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'schoolGrade')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => {
          if (!v || v.length === 0) return incorrectValue
          return <div>{v}</div>
        },
      },
      {
        title: t('組'),
        dataIndex: ['attributes', 'schoolClass'],
        key: 'schoolClass',
        fixed: 'left',
        width: isUsingJp ? 40 : 78,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'schoolClass')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => {
          if (!v || v.length === 0) return incorrectValue
          return <div>{v}</div>
        },
      },
      {
        title: t('番'),
        dataIndex: ['attributes', 'schoolAttendanceNumber'],
        key: 'schoolAttendanceNumber',
        fixed: 'left',
        width: isUsingJp ? 40 : 92,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'schoolAttendanceNumber')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => {
          if (!v || v.length === 0) return incorrectValue
          return <div>{v}</div>
        },
      },
      {
        title: t('性別'),
        dataIndex: ['attributes', 'gender'],
        key: 'gender',
        fixed: 'left',
        width: 60,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          const gender1 = a?.attributes?.gender
          const gender2 = b?.attributes?.gender

          if (!gender1 && !gender2) return 1
          if (!gender1) return -1
          if (!gender2) return 1
          return gender1.localeCompare(gender2)
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => (
          <div>{v ? (v === 'MALE' ? t('男') : t('女')) : '-'}</div>
        ),
      },
      {
        title: t('名前'),
        dataIndex: ['attributes'],
        key: 'name',
        fixed: 'left',
        width: 100,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'name')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => {
          if (!v || v.length === 0) return incorrectValue
          return (
            <div className="whitespace-normal">
              {t('フルネーム', {
                familyName: v.familyName,
                givenName: v.givenName,
              })}
            </div>
          )
        },
      },
      {
        title: t('GIGAID'),
        dataIndex: ['username'],
        key: 'username',
        fixed: 'left',
        width: 100,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'username')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        // render: (v) => {
        //   if (!v || v.length === 0) return incorrectValue
        //   return (
        //     <div className="whitespace-normal">
        //       {t('フルネーム', {
        //         familyName: v.familyName,
        //         givenName: v.givenName,
        //       })}
        //     </div>
        //   )
        // },
      },
      {
        title: t('生年月日'),
        dataIndex: ['attributes'],
        key: 'birthday',
        width: 138,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'birthday')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => {
          if (!v || v.length === 0) return incorrectValue
          return (
            <div className="whitespace-nowrap">
              {v.yearBirth &&
                v.monthBirth &&
                v.dayBirth &&
                t('年月日', {
                  year: v.yearBirth,
                  month: t(`${v.monthBirth}月`),
                  day: v.dayBirth,
                })}
            </div>
          )
        },
      },

      {
        title: '',
        dataIndex: ['attributes'],
        key: 'actions',
        width: isUsingJp ? 58 : 74,
        render: (v, record: IStudent) => {
          return (
            <div
              className={
                !v.testResults || v.testResults.length === 0
                  ? 'bg-gray-160 -m-2 px-2'
                  : ''
              }
            >
              <Button
                type="primary"
                className="ant-btn-xs text-10px test-result-details-btn"
                onClick={async (event) => {
                  event.preventDefault()
                  try {
                    const testResArr = record.attributes
                      .testResults as TestResult[]

                    const currYearRes = testResArr.find(
                      (item) => item.testYear === currentYear,
                    )
                    // TODO: fix
                    // @ts-ignore
                    setIdModal(testResArr.studentId)

                    form.setFieldsValue({
                      weight: currYearRes?.sizeTest?.weight,
                      height: currYearRes?.sizeTest?.height,
                      gripNotMeasurable: currYearRes?.grip?.isNotMeasurable,
                      gripMemo: currYearRes?.grip?.memo,
                      sitUpsNotMeasurable: currYearRes?.sitUps?.isNotMeasurable,
                      sitUpsMemo: currYearRes?.sitUps?.memo,
                      bendingNotMeasurable:
                        currYearRes?.bending?.isNotMeasurable,
                      bendingMemo: currYearRes?.bending?.memo,
                      sideJumpNotMeasurable:
                        currYearRes?.sideJump?.isNotMeasurable,
                      sideJumpMemo: currYearRes?.sideJump?.memo,
                      enduranceRunNotMeasurable:
                        currYearRes?.enduranceRun?.isNotMeasurable,
                      enduranceRunMemo: currYearRes?.enduranceRun?.memo,
                      sprintRunNotMeasurable:
                        currYearRes?.sprintRun?.isNotMeasurable,
                      sprintRunMemo: currYearRes?.sprintRun?.memo,
                      standingJumpNotMeasurable:
                        currYearRes?.standingJump?.isNotMeasurable,
                      standingJumpMemo: currYearRes?.standingJump?.memo,
                      handballThrowNotMeasurable:
                        currYearRes?.handballThrow?.isNotMeasurable,
                      handballThrowMemo: currYearRes?.handballThrow?.memo,
                      gripRight1: currYearRes?.grip?.gripRight1,
                      gripRight2: currYearRes?.grip?.gripRight2,
                      gripLeft1: currYearRes?.grip?.gripLeft1,
                      gripLeft2: currYearRes?.grip?.gripLeft2,
                      sitUps: currYearRes?.sitUps?.sitUps,
                      bending1: currYearRes?.bending?.bending1,
                      bending2: currYearRes?.bending?.bending2,
                      sideJump1: currYearRes?.sideJump?.sideJump1,
                      sideJump2: currYearRes?.sideJump?.sideJump2,
                      shuttleRunCount: currYearRes?.shuttleRun?.shuttleRunCount,
                      shuttleRunNotMeasurable:
                        currYearRes?.shuttleRun?.isNotMeasurable,
                      sprintRunSeconds:
                        currYearRes?.sprintRun?.sprintRunSeconds,
                      standingJump1: currYearRes?.standingJump?.standingJump1,
                      standingJump2: currYearRes?.standingJump?.standingJump2,
                      handballThrow1:
                        currYearRes?.handballThrow?.handballThrow1,
                      handballThrow2:
                        currYearRes?.handballThrow?.handballThrow2,
                      enduranceRun: currYearRes?.enduranceRun?.value,
                      runningTime: currYearRes?.enduranceRun?.runningTime,
                      runningTimeSeconds:
                        currYearRes?.enduranceRun?.runningTimeSeconds,
                    })

                    const newIsNotMeasurable = {}
                    getInputListOfTestRes(t).forEach((input) => {
                      const key = input.key
                      newIsNotMeasurable[key] =
                        currYearRes?.[key]?.isNotMeasurable
                    })

                    // Set state updates in sequence to avoid race conditions
                    await Promise.resolve()
                    setSelectedStudent(record)
                    setIsNotMeasurable(newIsNotMeasurable)
                    setShowDetailModal(true)
                  } catch (err) {
                    console.error('Error in details button click:', err)
                  }
                  return false
                }}
              >
                {t('詳細')}
              </Button>
            </div>
          )
        },
      },
      {
        title: t('総得点'),
        dataIndex: ['attributes', 'testResults'],
        key: 'points',
        className: 'text-center-f whitespace-nowrap',
        width: isUsingJp ? 70 : 128,
        sorter: (a, b) => {
          return sortTestResults(a, b, 'points')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, r) => {
          if (!v || v.length === 0) return incorrectValue
          const tr = v.find((d) => d.testYear === currentYear)

          return <div id={`${r._id}_points`}>{tr ? tr?.points : null}</div>
        },
      },
      {
        title: t('評価'),
        dataIndex: ['attributes', 'testResults'],
        key: 'rank',
        width: isUsingJp ? 60 : 128,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'rank')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, r) => {
          if (!v || v.length === 0) return incorrectValue
          const tr = v.find((d) => d.testYear === currentYear)
          const status = checkIfIsAllTestResInputted(
            v,
            hasEnduranceRun,
            inputKeys,
            currentYear,
          )
          const showRanking = status || checkIfIsValidRank(tr, hasEnduranceRun)

          return (
            <div id={`${r._id}_rank`}>
              {showRanking ? tr?.rank : incorrectValue}
            </div>
          )
        },
      },
      {
        title: t('身長'),
        dataIndex: [],
        key: 'height',
        width: 140,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'height')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v) => {
          return EditableCell(v?._id, 'height', false)
        },
      },
      {
        title: t('体重'),
        dataIndex: [],
        key: 'weight',
        width: 140,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'weight')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v: { _id: string } | undefined) => {
          // @ts-ignore
          return EditableCell(v?._id, 'weight', false)
        },
      },
      {
        title: t('BMI'),
        dataIndex: ['attributes', 'testResults'],
        key: 'bmi',
        width: 60,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'bmi')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v: { _id: string } | undefined, r) => {
          return <div id={`${r?._id}_bmi`}>{renderTest(v, 'bmi')}</div>
        },
      },
      {
        title: t('握力'),
        dataIndex: [],
        key: 'grip',
        width: isUsingJp ? 826 : 698,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'grip')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v: { _id: string } | undefined, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.grip?.isNotMeasurable
          // @ts-ignore
          return EditableCell(v?._id, 'grip', isDisabled)
        },
      },
      {
        title: t('上体起こし'),
        dataIndex: [],
        key: 'sitUps',
        width: isUsingJp ? 140 : 176,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'sitUps')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v: { _id: string } | undefined, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.sitUps?.isNotMeasurable
          // @ts-ignore
          return EditableCell(v?._id, 'sitUps', isDisabled)
        },
      },
      {
        title: t('長座体前屈'),
        dataIndex: [],
        key: 'bending',
        width: 395,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'bending')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.bending?.isNotMeasurable
          return EditableCell(v?._id, 'bending', isDisabled)
        },
      },
      {
        title: t('反復横跳び'),
        dataIndex: [],
        key: 'sideJump',
        width: 395,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'sideJump')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.sideJump?.isNotMeasurable
          return EditableCell(v?._id, 'sideJump', isDisabled)
        },
      },
      {
        title: t('持久走'),
        dataIndex: [],
        key: 'enduranceRun',
        width: 300,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'enduranceRun')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.enduranceRun?.isNotMeasurable
          return EditableCell(v?._id, 'enduranceRun', isDisabled)
        },
      },
      {
        title: t('20mシャトルラン'),
        dataIndex: [],
        key: 'shuttleRun',
        width: isUsingJp ? 152 : 168,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'shuttleRun')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.shuttleRun?.isNotMeasurable
          return EditableCell(v?._id, 'shuttleRun', isDisabled)
        },
      },
      {
        title: t('50m走'),
        dataIndex: [],
        key: 'sprintRun',
        width: 140,
        editable: true,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'sprintRun')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.sprintRun?.isNotMeasurable
          return EditableCell(v?._id, 'sprintRun', isDisabled)
        },
      },
      {
        title: t('立ち幅跳び'),
        dataIndex: [],
        key: 'standingJump',
        width: 395,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'standingJump')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.standingJump?.isNotMeasurable
          return EditableCell(v?._id, 'standingJump', isDisabled)
        },
      },
      {
        title: t('ボール投げ'),
        dataIndex: [],
        key: 'handballThrow',
        width: 395,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, 'handballThrow')
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        render: (v: { _id: string } | undefined, d) => {
          const isDisabled =
            d?.attributes?.testResults?.[0]?.handballThrow?.isNotMeasurable
          // @ts-ignore
          return EditableCell(v?._id, 'handballThrow', isDisabled)
        },
      },
    ]

    if (!hasEnduranceRun) {
      return tableColumnsRes.filter(
        (tableColumn) => tableColumn.key !== 'enduranceRun',
      )
    }

    return tableColumnsRes
  }, [
    countConf,
    form,
    formEdit,
    hasEnduranceRun,
    inputKeys,
    isElementarySchool,
    isHighlightInputtedVal,
    isUsingJp,
    school,
    t,
    total,
  ])

  useEffect(() => {
    if (filteredStudents?.length) {
      const formEditSave = setFormAll(filteredStudents, currentYear)
      formEdit.setFieldsValue(formEditSave)
    }
  }, [filteredStudents, formEdit])

  const weightHeightTabTitleDefaultWidth = isUsingJp ? 'w-12' : 'w-16'

  return (
    <Dashboard selectedMenu={4} navbar={t('体力テスト')}>
      <>
        <Row className="w-full justify-center">
          <Col className="mt-6" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="flex justify-between">
              {/* filter for table */}
              <div
                className={`space-x-2 pb-2 print:hidden mt-14 ${
                  isUsingJp
                    ? 'test-filter-group-btn-ja'
                    : 'test-filter-group-btn-en'
                }`}
              >
                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={schoolGrade}
                  onChange={onChangeGrade}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="grade-default" value={0}>
                    {t('全学年')}
                  </Option>
                  {filterData?.grades.map((v) => (
                    <Option value={v} key={v}>
                      {t('個別学年', {
                        count: v,
                        ordinal: !isUsingJp,
                      })}
                    </Option>
                  ))}
                </Select>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={schoolClass}
                  onChange={onChangeClass}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="class-default" value={0}>
                    {t('全組')}
                  </Option>
                  {filterData?.classes.map((_class) => {
                    return (
                      <Option key={`class-${_class}`} value={_class}>
                        {t('個別組', {
                          count: _class,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    )
                  })}
                </Select>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-28' : 'w-34'}`}
                  value={filterGender}
                  onChange={onChangeGender}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="gender-0" value="">
                    {t('全性別')}
                  </Option>
                  <Option key="gender-male" value="MALE">
                    {t('男性')}
                  </Option>
                  <Option key="gender-female" value="FEMALE">
                    {t('女性')}
                  </Option>
                </Select>
              </div>
            </div>

            {/* statistic at the top of table */}
            <div className="flex justify-between">
              <div className="mb-0 pb-0">
                <div className="text-10px font-black">
                  {t('生徒登録人数', {
                    student: isElementarySchool ? '児童' : '生徒',
                  })}
                  : {total}
                </div>
                <div className="text-10px font-black">
                  {t('入力済み人数')}:{' '}
                  <span id="totalCompletion">{totalCompletion}</span>
                </div>
                <div className="text-10px font-black pb-2">
                  {t('未登録人数')}:{' '}
                  <span id="totalUncompletion">{total - totalCompletion}</span>
                </div>

                <div className="text-base mb-2 mt-1 flex items-center">
                  <span className="mr-2">{t('要チェック値の強調')}</span>
                  {/* a toggle button */}
                  <Switch
                    checked={isHighlightInputtedVal}
                    onChange={() => {
                      setIsHighlightInputtedVal(!isHighlightInputtedVal)
                    }}
                  />
                </div>
              </div>

              <div className="mb-2 flex-col justify-items-end h-16">
                <div
                  className="text-black p-2"
                  style={{ backgroundColor: '#fef2cc' }}
                >
                  ※入力した値は自動で保存されます。
                </div>
                <div className="flex align-middle justify-center text-right text-black">
                  <div className="hidden" ref={submittedRef}>
                    <Space>
                      <CheckCircleOutlined style={{ color: 'black' }} />
                    </Space>
                    <div className="ml-1 pt-2">保存済み</div>
                  </div>

                  <div className="hidden" ref={submittingRef}>
                    <Space>
                      <SyncOutlined spin style={{ color: 'black' }} />
                    </Space>
                    <div className="ml-1 pt-2">保存中</div>
                  </div>
                </div>
              </div>
            </div>
            {/* end of statistic at the top of table */}

            <Form form={formEdit} component={false}>
              <Table
                columns={tableColumns as ColumnsType<object>}
                tableLayout="fixed"
                dataSource={filteredStudents}
                loading={{
                  spinning: loadingStudent || reloading || countLoading,
                  indicator: (
                    <CircularProgressLoading percent={loadingPercent} />
                  ),
                }}
                rowKey="_id"
                size="small"
                style={{ minWidth: 940 }}
                scroll={{ x: 1500, y: '70vh' }}
                pagination={{
                  pageSize,
                  defaultPageSize,
                  position: ['bottomCenter'],
                  pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
                  showSizeChanger: true,
                  total,
                  current: currentPage,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page)
                    setPageSize(pageSize)
                  },
                }}
                bordered={true}
              />
            </Form>
          </Col>
        </Row>

        <TestDetailsModal
          showDetailModal={showDetailModal}
          onCancel={() => {
            setShowDetailModal(false)
            form.resetFields()
            setIdModal('')
          }}
          form={form}
          onFinish={onFinishRegisterByDetailModal}
          loading={isDetailLoading}
          getInputList={getInputList}
          renderInput={renderInput}
          weightHeightTabTitleDefaultWidth={weightHeightTabTitleDefaultWidth}
        />

        <MeasurableModal
          isModalOpen={measurableModalObj}
          setIsModalOpen={setMeasurableModalObj}
          handleOk={async () => {
            setMeasurableModalObj(false)

            if (!school) {
              toast.error(
                `${t('エラーが発生しました。')} [school is not found]`,
              )
              return
            }

            if (!measurableModalObj || typeof measurableModalObj !== 'object') {
              toast.error(
                `${t('エラーが発生しました。')} [studentId is not found]`,
              )
              return
            }

            const apiUrl = `/alpha/v1/school/student/${measurableModalObj.studentId}/result/measurable`

            setReloading(true)

            try {
              await Api.post(apiUrl, {
                isNotMeasurable: false,
                keyId: measurableModalObj.keyId,
              })

              // reload the table
              await getStudents({
                variables: {
                  input: {
                    schoolId: school._id,
                    testYear: currentYear,
                    schoolClass,
                    schoolGrade,
                  },
                },
              })
            } catch (err) {
              toast.error(
                `${t('エラーが発生しました。')} [${JSON.stringify(
                  (err as Error).message,
                )}]`,
              )

              console.error(`when call ${apiUrl} - error:`, err)
            } finally {
              setReloading(false)
            }
          }}
        />
      </>
    </Dashboard>
  )
}

export default TestInputPage
