import { STORAGE_URL } from '~/utils/constants'
import { exerciseProgramVideosBaseUrlLength } from './const'

export const getLowUrl = (url: string) => {
  const videoId = url.slice(
    exerciseProgramVideosBaseUrlLength,
    exerciseProgramVideosBaseUrlLength + 3,
  )

  return `${STORAGE_URL}/example_videos_low_quality/${videoId}.mp4`
}
